/* eslint-disable react/prop-types */
import './HoverableText.scss';
import { useState } from 'react';
import { Tooltip } from 'react-tooltip';

/* eslint-disable react/prop-types */
export default function HoverableText({ text, info, styleClass }) {
  const [hoverId] = useState(String(Math.random())); // Using the same id for multiple tooltips on the same page will open all tooltips.
  const [showInfo, setShowInfo] = useState(false);
  return (
    <>
      <p
        className={`${styleClass} ${
          showInfo === true ? 'hover-info-highlight' : ''
        }`}
        onMouseEnter={() => {
          if (info) setShowInfo(true);
        }}
        onMouseLeave={() => setShowInfo(false)}
        data-tooltip-id={hoverId}
      >
        {text}
      </p>
      {info ? (
        <Tooltip className="hover-info-tooltip" id={hoverId} place="top">
          <p>{info}</p>
        </Tooltip>
      ) : (
        ''
      )}
    </>
  );
}
