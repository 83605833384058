import './BankTypeModal.scss';
import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import LoadingBar from '../../../Components/LoadingBar/LoadingBar';

/* eslint-disable react/prop-types */
export default function BankTypeModal({ bank, redirectToBank, handleClose }) {
  const [redirecting, setRedirecting] = useState(false);

  return (
    <Modal
      show
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="border-0 bank-type-modal"
    >
      <Modal.Header>
        <Button className="btn-close" bsPrefix="button" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body className="border-0">
        <div className="bank-type-header">
          <p className="bank-name">{bank.name}</p>
          <img src={bank.logo} alt="bank_logo" />
        </div>
        <div className="bank-type-content">
          {redirecting === true ? (
            <LoadingBar active relative />
          ) : (
            <>
              <p className="action-title">Välj kontotyp</p>
              <div className="bank-types">
                {bank.psuTypes
                  .sort((a, b) => a - b)
                  .map((psuType) => {
                    return (
                      <button
                        className="bank-type-button"
                        type="button"
                        key={psuType}
                        onClick={() => {
                          setRedirecting(true);
                          redirectToBank(bank.name, bank.country, psuType);
                        }}
                      >
                        {psuType === 0 ? 'Business' : 'Personal'}
                      </button>
                    );
                  })}
              </div>
            </>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}
