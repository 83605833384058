import HomeNav from '../HomeNav';
import Background from '../Components/Background';
import Footer from '../Components/Footer/Footer';

export default function TermsAndConditions() {
  return (
    <div className="xpektor-home">
      <div className="policy-intro">
        <HomeNav />
        <div className="intro-section">
          <p className="section-title">Sverige</p>
          <h1>Allmänna regler och villkor</h1>
          <p className="section-text">
            Senast uppdaterad: 27 Aug 2024 - Version: 1.1
          </p>
        </div>
      </div>
      <Background color1="white">
        <div className="policy-wrapper">
          <div className="policy-header">
            <p className="policy-title">Allmänna regler och villkor</p>
            <p>Senast uppdaterad: 27 Aug 2024</p>
          </div>
          <div className="toc-section">
            <a href="#terms-1">1. Bakgrund</a>
            <a href="#terms-2">2. Definitioner</a>
            <a href="#terms-3">3. Licens</a>
            <a href="#terms-4">4. Kundens skyldigheter</a>
            <a href="#terms-5">5. Xpektor Tech AB skyldigheter</a>
            <a href="#terms-6">6. Kunddata</a>
            <a href="#terms-7">7. Personuppgifter</a>
            <a href="#terms-8">8. Tredjepartsleverantörer</a>
            <a href="#terms-9">9. Betalning</a>
            <a href="#terms-10">10. Äganderätt</a>
            <a href="#terms-11">11. Sekretess</a>
            <a href="#terms-12">12. Skadestånd</a>
            <a href="#terms-13">13. Begränsning av ansvar</a>
            <a href="#terms-14">14. Avtalstid och uppsägning</a>
            <a href="#terms-15">15. Allmänna bestämmelser</a>
          </div>
          <div className="policy-section">
            <p className="section-title" id="terms-1">
              1. Bakgrund
            </p>
            <p>
              {`1.1 Följande villkor ska gälla tillsammans med
              orderbekräftelseformuläret ("OBF") och tillsammans med eventuella
              ytterligare villkor för specifika tillägg för tillhandahållande av
              licenser till Xpektor Tech ABs produkt (enligt definitionen nedan)
              till kunden, enligt vad som anges i OBF och i dessa allmänna villkor.`}
            </p>
            <p>
              1.2 I händelse av att OBF är oförenlig med dessa villkor ska
              bestämmelserna i OBF ha företräde.
            </p>
            <p>
              1.3 I händelse av motstridig lydelse mellan bestämmelserna i
              Personuppgiftsbiträdesavtalet och övriga avtal mellan parterna,
              ska Personuppgiftsbiträdesavtalet äga företräde i frågor som rör
              personuppgifter.
            </p>
          </div>
          <div className="policy-section">
            <p className="section-title" id="terms-2">
              2. Definitioner
            </p>
            <p>
              Följande definitioner ska gälla för dessa villkor:
              <br />
              Med &quot;<b>avtal</b>&quot; avses dessa villkor tillsammans med
              OBF, Personuppgiftsbiträdesavtal och tillsammans med eventuella
              ytterligare villkor för specifika tillägg.
              <br />
              &quot;<b>Auktoriserade användare</b>&quot; avser de namngivna
              individer (upp till det överenskomna antalet användare som anges i
              OBF) som är auktoriserade av Kunden och Xpektor Tech AB att
              använda Produkten enligt detta Avtal, och som alltid ska vara
              anställda hos Kunden som helt eller huvudsakligen befinner sig på,
              eller inom, webbplatsen, landet, regionen eller den globala
              platsen(erna) enligt vad som anges i OBF.
              <br />
              &quot;<b>Kund</b>&quot; avser avtalspart gentemot Xpektor Tech AB
              under dessa Allmänna regler och villkor.
              <br />
              &quot;<b>Kunddata</b>&quot; avser all information eller data som
              tillhandahålls av Kunden till Xpektor Tech AB och som härrör från
              Kundens organisation genom användning av Produkten och som eller
              på annat sätt tillhandahålls från Kunden till Xpektor Tech AB för
              fullgörandet av detta Avtal.
              <br />
              &quot;<b>Kundens personuppgifter</b>&quot; avser personuppgifter
              som tas emot från kunden, eller i samband med behöriga användares
              användning av produkten eller från att underlätta kundens
              användning av produkten och som avser personuppgifter hänförliga
              till Kunds organisation.
              <br />
              Med <b>ikraftträdandedatum</b> avses datum för undertecknande i
              OBF.
              <br />
              &quot;<b>Intern affärsanvändning</b>&quot; avser användning av
              produkten inom kundens verksamhet eller organisation, i syfte att
              främja kundens verksamhet.
              <br />
              &quot;<b>Licensavgift</b>&quot; avser den licensavgift som kunden
              ska betala till Xpektor Tech AB för tillhandahållandet av
              produkten, enligt vad som anges i OBF.
              <br />
              &quot;<b>Licensperiod</b>&quot; avser den period under vilken
              produkten ska göras tillgänglig för kunden enligt vad som anges i
              OBF.
              <br />
              &quot;<b>Part</b>&quot; och &quot;<b>parter</b>&quot; avser
              antingen Kund och Xpektor Tech AB respektive Kund och Xpektor Tech
              AB tillsammans.
              <br />
              &quot;<b>Personuppgifter</b>&quot; avser personuppgifter enligt
              definitionen i den när som helst tillämpliga lagstiftningen för
              EU.
              <br />
              &quot;<b>Sekretesspolicy</b>&quot; avser Xpektor Tech AB policy
              för behandling av personuppgifter, som finns på www.Xpektor Tech
              AB.com
              <br />
              &quot;<b>Slutanvändare</b>&quot; avser av Kund inbjuden användare
              tillhörande den organisation som är föremål för granskning i kunds
              syfte.
              <br />
              &quot;<b>Slutanvändardata</b>&quot; avser de uppgifter som
              slutanvändare tillför och där behandlingen av eventuella
              personuppgifter sker enligt instruktion i tecknat
              Personuppgiftsbiträdesavtal.
              <br />
              &quot;<b>Produkt</b>&quot; avser Xpektor Tech AB molnbaserade
              programvara-som-en-tjänst-applikation avsedd för intressenters
              inriktning och engagemang, inklusive de moduler som är
              tillgängliga per ikraftträdandedatumet, och inklusive eventuella
              tillägg som kunden har köpt åtkomst till i enlighet med OBF.
            </p>
          </div>
          <div className="policy-section">
            <p className="section-title" id="terms-3">
              3. Licens
            </p>
            <p>
              3.1 Med förbehåll för villkoren i detta avtal beviljar Xpektor
              Tech AB härmed kunden en licens till produkten enligt vad som
              anges i detta avsnitt.
              <br />
              3.2 För licenser ska detta avsnitt 3.2 gälla:
              <br />
              Med förbehåll för villkoren i detta avtal beviljar Xpektor Tech AB
              härmed kunden en icke-exklusiv, icke-överförbar, royaltybärande
              licens för att få tillgång till produkten under licensperioden för
              internt affärsbruk.
              <br />
              3.3 Kunden ska ha en icke-exklusiv, royaltyfri licens att använda
              innehåll som skapats i Produkten såsom visualiseringar, grafer
              etc. (med undantag för innehåll som ägs av en tredje part) i
              offentliga rapporter, förutsatt att Kunden tydligt anger att
              sådant innehåll kommer från Xpektor Tech AB Produkt och ett
              upphovsrättsmeddelande ingår där det är uppenbart att
              upphovsrätten till innehållet ägs av Xpektor Tech AB.
            </p>
          </div>
          <div className="policy-section">
            <p className="section-title" id="terms-4">
              4. Kundens skyldigheter
            </p>
            <p>
              4.1 Kunden förbinder sig att:
              <br />
              (a) betala licensavgiften till Xpektor Tech AB för
              tillhandahållandet av produkten, enligt vad som regleras i avsnitt
              9;
              <br />
              (b) följa detta avtal och Xpektor Tech AB upphovsrättspolicy,
              tillgänglig vid förfrågan, och se till att dess auktoriserade
              användare gör detsamma. Kunden accepterar ansvar för förlust,
              skada, kostnad eller annat ansvar Xpektor Tech AB kan ådra sig
              till följd av ett brott mot detta avtal av en auktoriserad
              användare;
              <br />
              (c) följa alla tillämpliga lagar och förordningar med avseende på
              dess verksamhet enligt detta avtal och licensen. Kunden tar fullt
              ansvar för att det är lagligt tillåtet att komma åt och använda
              produkten i det land /de länder där kunden använder produkten;
              <br />
              (d) säkerställa att Kundens nätverk och system uppfyller de
              relevanta specifikationerna som tillhandahålls av Xpektor Tech AB
              från tid till annan; och
              <br />
              (e) vara ensam ansvarig för att skaffa och underhålla sina
              nätverksanslutningar och telekommunikationslänkar från sina system
              till Xpektor Tech ABs produkt, och alla problem, villkor,
              förseningar, leveransfel och all annan förlust eller skada som
              uppstår från eller i samband med kundens nätverksanslutningar
              eller telekommunikationslänkar.
              <br />
              4.2 I förhållande till sina Auktoriserade Användare åtar sig
              Kunden att:
              <br />
              (a) Kunden ska förse Xpektor Tech AB med en lista över personer
              som ska vara Behöriga Användare. Listan kan uppdateras av kunden
              genom att ta bort eller lägga till namngivna individer upp till
              det antal som anges i OBF förutsatt att kunden har ett giltigt
              skäl (exempel på giltiga skäl inkluderar uppsägning av
              anställning, överföring av arbetsuppgifter etc.) och efter
              skriftligt samtycke från Xpektor Tech AB (sådant samtycke får inte
              hållas orimligt);
              <br />
              (b) endast dess Auktoriserade användare får åtkomst till att
              använda Produkten;
              <br />
              (c) Den får inte tillåta att något konto eller
              inloggningsuppgifter används av mer än en enskild behörig
              användare åt gången.
              <br />
              (d) dess Auktoriserade Användare ska omedelbart upphöra att ha
              tillgång till, använda och dra nytta av Produkten när deras
              anställningsförhållande med Kunden upphör;
              <br />
              (e) Varje Behörig Användare ska hålla lösenordet för sin
              användning av Produkten säkert och konfidentiellt.
              <br />
              4.3 Kunden ska inte, och ska säkerställa att dess Auktoriserade
              användare inte får tillgång till, ladda upp, lägga till, bearbeta,
              lagra, distribuera eller överföra virus eller skadligt material
              och/eller kod, eller något material, text eller anteckningar till
              eller genom Produkten som är olagliga, skadliga, hotfulla,
              ärekränkande, obscena, kränker en tredje parts immateriella
              rättigheter eller äganderätt, trakassera eller rasistiskt eller
              etniskt stötande, underlätta olaglig aktivitet, skildra sexuellt
              explicita bilder, främja olagligt våld, är diskriminerande baserat
              på ras, kön, färg, religiös tro, sexuell läggning, ålder,
              funktionshinder eller annan olaglig aktivitet eller kan orsaka
              skada på någon egendom.
              <br />
              4.4 Kunden ska inte, och ska se till att dess Behöriga användare
              inte ska:
              <br />
              (a) förutom i den utsträckning som uttryckligen tillåts enligt
              detta avtal eller i enlighet med tillämplig tvingande lag, försöka
              kopiera, modifiera, duplicera, skapa härledda verk från, rama in,
              spegla, publicera, ladda ner, visa, överföra eller distribuera
              hela eller någon del av produkten i någon form eller media eller
              på något sätt;
              <br />
              (b) försöka omvänd kompilera, demontera, bakåtkompilera eller på
              annat sätt reducera hela eller någon del av produkten till
              människouppskattbar form utom i den utsträckning som är tillåten
              enligt tillämplig tvingande lag;
              <br />
              (c) använda automatisk hämtningsutrustning (inklusive sökrobotar,
              spindlar eller informationsaggregatortjänster),
              <br />
              (d) få tillgång till hela eller någon del av produkten för att
              bygga en konkurrerande produkt; eller
              <br />
              (e) licensiera, återförsälja, hyra ut, leasa, överföra, tilldela,
              distribuera, visa, avslöja eller på annat sätt kommersiellt
              utnyttja, eller på annat sätt göra produkten tillgänglig för någon
              tredje part utom dess auktoriserade användare, om inte annat
              uttryckligen tillåts i avsnitt 3.
              <br />
              4.5 Kunden ska göra alla rimliga ansträngningar för att förhindra
              obehörig åtkomst till eller användning av Produkten och ska
              omedelbart meddela Xpektor Tech AB i händelse av att Kunden
              misstänker sådan obehörig åtkomst eller användning.
              <br />
              4.6 Xpektor Tech AB har rätt att övervaka användningen av och
              åtkomsten till produkten för att verifiera kundens efterlevnad av
              detta avtal och/eller för att uppfylla tillämplig lag, förordning
              eller auktoriserad myndighetsbegäran.
              <br />
              4.7 Utan att det påverkar någon annan rättighet eller gottgörelse
              kan Xpektor Tech AB när som helst inaktivera alla behöriga
              användares åtkomst, om kunden eller en av dess auktoriserade
              användare enligt Xpektor Tech AB eget gottfinnande har misslyckats
              med att följa avsnitten 4.2, 4.3 eller 4.4.
            </p>
          </div>
          <div className="policy-section">
            <p className="section-title" id="terms-5">
              5. Xpektor Tech AB skyldigheter
            </p>
            <p>
              5.1 Xpektor Tech AB ska under licensperioden göra produkten
              tillgänglig för kunden med förbehåll för villkoren i detta avtal.
              <br />
              5.2 Xpektor Tech AB åtar sig att säkerställa att
              tillhandahållandet av produkten ska utföras i enlighet med detta
              avtal och med rimlig skicklighet och omsorg.
              <br />
              5.3 Xpektor Tech AB ska inte ha något ansvar för bristande
              överensstämmelse av produkten som orsakas av användning av
              produkten i strid med Xpektor Tech AB instruktioner, eller
              modifiering eller ändring av produkten av någon annan part än
              Xpektor Tech AB eller Xpektor Tech ABs vederbörligen auktoriserade
              entreprenörer eller agenter. Om produkten inte överensstämmer med
              ovanstående åtaganden ska Xpektor Tech AB, på egen bekostnad och
              efter eget gottfinnande, använda rimliga kommersiella
              ansträngningar för att korrigera sådan bristande överensstämmelse
              omedelbart. Sådan korrigering ska utgöra kundens enda och
              exklusiva gottgörelse för brott mot åtagandet som anges i avsnitt
              5.2. Oaktat det föregående, Xpektor Tech AB:
              <br />
              (a) garanterar inte att kundens användning av produkten ska vara
              oavbruten eller felfri; inte heller att produkten och/eller den
              information som kunden erhåller genom produkten kommer att
              uppfylla kundens krav; och
              <br />
              (b) inte ansvarar för förseningar, leveransfel, förlust av data
              eller annan förlust eller skada till följd av överföring av data
              via kommunikationsnät och faciliteter, inklusive Internet, och
              kunden erkänner att produkten kan vara föremål för begränsningar,
              förseningar och andra problem som är förknippade med användningen
              av sådana kommunikationsanläggningar.
              <br />
              5.4 Xpektor Tech AB ska använda kommersiellt rimliga
              ansträngningar för att göra produkten tillgänglig 24 timmar om
              dygnet, sju dagar i veckan, med undantag för de begränsningar som
              anges i avsnitten 5.5 och 13.2.
              <br />
              5.5 Xpektor Tech AB förbehåller sig rätten att tillfälligt stänga
              av Kundens tillgång till Produkten i syfte att underhålla eller
              uppgradera, men Xpektor Tech AB ska göra rimliga ansträngningar
              för att göra en sådan avstängning under perioder med låg
              efterfrågan på åtkomst och för att minimera perioden därav.
              <br />
              5.6 Om Produkten blir, eller enligt Xpektor Tech ABs uppfattning
              sannolikt kommer att bli, föremål för ett förfarande eller anspråk
              på intrång i immateriella rättigheter, ska Xpektor Tech AB, efter
              eget val och bekostnad: (i) erhålla rätten för Kunden att
              fortsätta använda Produkten i enlighet med detta Avtal, (ii)
              ersätta eller modifiera Produkten så att den inte gör intrång;
              eller (iii) om (i ) eller (ii) inte är genomförbara, ska Xpektor
              Tech AB ta bort den intrångsgörande delen av produkten. Om Xpektor
              Tech AB inte rimligen kan erhålla gottgörelserna i (i) eller (ii)
              och (iii) inte heller skulle vara genomförbart, ska Xpektor Tech
              AB (iv) säga upp licensen för den intrångsgörande produkten, vid
              vilken tidpunkt detta avtal ska upphöra. Kundens enda gottgörelse
              med avseende på detta är Xpektor Tech ABs skyldighet att
              återbetala kunden, pro rata, för eventuell återstående tidsperiod
              av licensperioden.
            </p>
          </div>
          <div className="policy-section">
            <p className="section-title" id="terms-6">
              6. Kunddata
            </p>
            <p>
              6.1 Kunden ger härmed Xpektor Tech AB tillstånd att samla in och
              behandla kunddata som levereras till och / eller samlas in av
              Xpektor Tech AB i syfte att utföra sina skyldigheter enligt detta
              avtal samt ge support till kunden och förbättra produkten.
              Kunddata ska hållas säkra och inga kunddata ska delas med någon
              tredje part, med undantag för molnhotellleverantörer som behövs
              för genomförandet av detta avtal.
            </p>
          </div>
          <div className="policy-section">
            <p className="section-title" id="terms-7">
              7. Personuppgifter
            </p>
            <p>
              7.1 Kund åtar sig att underlätta för Xpektor att informera sina
              auktoriserade användare om hur Xpektor kan komma att behandla
              deras personuppgifter i syfte att utföra sina skyldigheter enligt
              detta avtal samt ge support till kunden och förbättra produkten,
              genom hänvisning till användarvillkor och Xpektors
              Integritetspolicy.
              <br />
              7.2 Xpektor Tech AB åtar sig att, i samband med tillhandahållandet
              av Produkten, följa Xpektor Tech AB Integritetspolicy avseende
              integritet och säkerhet för Kundens Personuppgifter.
              <br />
              7.3 Kunden ska följa all tillämplig integritets- och
              personuppgiftslagstiftning samt marknadsföringslagstiftning vid
              behandling av personuppgifter som nås eller hämtas från produkten.
              <br />
              7.4 Xpektor Tech AB:s behandling av Slutanvändardata regleras
              genom tecknat Personuppgiftsbiträdesavtal och enligt av Kund däri
              given instruktion.
            </p>
          </div>
          <div className="policy-section">
            <p className="section-title" id="terms-8">
              8. Tredjepartsleverantörer
            </p>
            <p>
              8.1 Kunden är medveten om att produkten kan möjliggöra eller
              hjälpa kunden att komma åt webbplatsinnehållet på tredje parts
              webbplatser via länkar eller liknande, och att kunden får tillgång
              till sådana webbplatser enbart på egen risk. Xpektor Tech AB gör
              inga utfästelser eller garantier och har inget ansvar eller
              skyldighet i förhållande till innehållet eller användningen av
              sådana tredje parts webbplatser.
            </p>
          </div>
          <div className="policy-section">
            <p className="section-title" id="terms-9">
              9. Betalning
            </p>
            <p>
              9.1 Kunden ska på Ikraftträdandedatumet tillhandahålla Xpektor
              Tech AB giltiga, aktuella och fullständiga kontakt- och
              faktureringsuppgifter. Xpektor Tech AB
              <br />
              9.2 Xpektor Tech AB ska skicka fakturor i enlighet med OBF.
              Fakturor tillhandahålls endast i elektroniskt format.
              <br />
              9.3 Påföljder vid sena betalningar. Om Xpektor Tech AB inte har
              mottagit betalning inom tjugo (20) dagar efter förfallodagen, och
              utan att det påverkar andra rättigheter och rättsmedel för Xpektor
              Tech AB:
              <br />
              (a) Xpektor Tech AB kan, utan ansvar gentemot kunden, inaktivera
              kundens lösenord, konto och åtkomst till hela eller delar av
              produkten och Xpektor Tech AB har ingen skyldighet att
              tillhandahålla någon eller hela produkten medan fakturan
              /fakturorna förblir obetalda; och
              <br />
              (b) 10 % ränta skall uppkomma, med början på förfallodagen och som
              fortsätter tills den är fullt betald.
            </p>
          </div>
          <div className="policy-section">
            <p className="section-title" id="terms-10">
              10. Äganderätt
            </p>
            <p>
              10.1 Kunden bekräftar och samtycker till att Xpektor Tech AB
              och/eller dess licensgivare äger alla immateriella rättigheter i
              förhållande till produkten. Med undantag för vad som uttryckligen
              anges häri, ger Xpektor Tech AB inte kunden några rättigheter till
              eller i dess eller dess licensgivares patent, uppfinningar,
              know-how, upphovsrätt, databasrättigheter, affärshemligheter,
              handelsnamn, varumärken (oavsett om de är registrerade eller
              oregistrerade), licenser eller andra rättigheter eller licenser
              med avseende på produkten. Detta ska också särskilt gälla
              immateriella rättigheter avseende databaser, metodik,
              datainsamling, datarengöring, dataanalyser, datapresentation,
              layouter, visualiseringar, leveransformat (tryckta leveranser,
              digitala leveranser och mjukvaruapplikationer), Xpektor Tech AB-
              logotyper och varumärken, algoritmer, källkod, programvara och
              Excel-filer.
            </p>
          </div>
          <div className="policy-section">
            <p className="section-title" id="terms-11">
              11. Sekretess
            </p>
            <p>
              11.1 Enligt detta avtal avses med &quot;konfidentiell
              information&quot; all information, teknisk eller kommersiell, som
              avslöjas från den ena parten till den andra parten i samband med
              detta avtal, oavsett om den är markerad som
              &quot;konfidentiell&quot; eller inte, inklusive, men inte
              begränsat till, data, ritningar, uppfinningar, filmer,
              dokumentation, know-how, programvara och design, oavsett om den
              kommuniceras i skriftlig, muntlig, grafisk, fysisk eller
              elektronisk form.
              <br />
              11.2 Konfidentiell information ska inte innehålla information som:
              <br />
              (a) är allmänt känd eller blir allmänt känd på något annat sätt än
              genom att den mottagande parten bryter mot detta avtal,
              <br />
              (b) den mottagande parten kan visa att den hade den laglig
              besittning innan den tog emot den från den röjande parten,
              <br />
              (c) lagligen avslöjas för den mottagande parten av en tredje part
              utan några begränsningar av avslöjandet; eller
              <br />
              (d) måste offentliggöras enligt tvingande tillämplig lag, av någon
              domstol med behörig jurisdiktion eller av något reglerings- eller
              förvaltningsorgan.
              <br />
              11.3 Parterna förbinder sig att använda den andra partens
              konfidentiella information endast för tillämpningen av detta avtal
              och att inte avslöja konfidentiell information till någon tredje
              part.
              <br />
              11.4 Varje part ska vidta alla rimliga åtgärder för att
              säkerställa att den andra partens konfidentiella information som
              den har tillgång till inte avslöjas eller distribueras av dess
              anställda i strid med villkoren i detta avtal. Kunden är fullt
              ansvarig för sina Auktoriserade Användare.
              <br />
              11.5 Detta avsnitt 11 ska överleva uppsägning av detta avtal,
              oavsett hur det uppstår, med fem (5) år.
            </p>
          </div>
          <div className="policy-section">
            <p className="section-title" id="terms-12">
              12. Skadestånd
            </p>
            <p>
              12.1 Kunden ska fullt ut försvara, ersätta och hålla Xpektor Tech
              AB skadeslöst mot anspråk, åtgärder, förfaranden, förluster,
              skador, utgifter och kostnader, inklusive utan begränsning ränta,
              påföljder och rimliga juridiska avgifter och utgifter eller annat
              ansvar som Xpektor Tech AB ådragit sig med avseende på (i) intrång
              i dess rättigheter (inklusive immateriella rättigheter); eller
              (ii) som härrör från kundens användning av produkten om kunden
              bryter mot detta avtal.
            </p>
          </div>
          <div className="policy-section">
            <p className="section-title" id="terms-13">
              13. Begränsning av ansvar
            </p>
            <p>
              13.1 Med undantag för vad som uttryckligen och specifikt anges i
              detta avtal:
              <br />
              (a) Xpektor Tech AB har inget ansvar för skador eller förluster
              som uppstår på grund av eller i enlighet med användningen eller
              prestandan av produkten, vare sig på grund av felaktighet, fel,
              utelämnande eller någon annan orsak, vare sig från Xpektor Tech
              AB, dess anställda, agenter eller någon annan person eller enhet:
              <br />
              (b) Xpektor Tech AB använder rimliga ansträngningar för att
              säkerställa att all information som ingår i produkten är korrekt;
              Xpektor Tech AB kan dock inte garantera riktigheten av den
              information som tillhandahålls via produkten, som tillhandahålls
              kunden &quot;i befintligt skick&quot;;
              <br />
              (c) Kunden är ensam ansvarig för sina slutsatser, beslut och
              åtgärder som fattas och baseras på användningen av produkten;
              <br />
              13.2 Xpektor Tech AB har inget ansvar gentemot kunden enligt detta
              avtal om det hindras från eller försenas med att utföra sina
              skyldigheter enligt detta avtal, eller från att bedriva sin
              verksamhet, genom handlingar, händelser, försummelser eller
              olyckor utanför dess rimliga kontroll (force majeure-händelser),
              inklusive, utan begränsning, strejker, lockouter eller andra
              arbetskonflikter (oavsett om de involverar Xpektor Tech ABs
              personal eller någon annan part), fel på en tjänst eller ett
              transport- eller telekommunikationsnät, krig, upplopp, civil
              uppståndelse, skadlig skada, efterlevnad av lag eller statlig
              order, regel, reglering eller riktning, olycka, haveri av
              anläggning eller maskiner, brand, översvämning, storm eller
              standard hos Xpektor Tech ABs eller underleverantörer, förutsatt
              att kunden meddelas om en sådan händelse och dess förväntade
              varaktighet.
              <br />
              13.3 Ingenting i detta avtal utesluter sådant ansvar för Xpektor
              Tech AB som inte kan uteslutas enligt tillämplig tvingande lag.
              <br />
              13.4 Om Xpektor Tech AB av någon anledning är ansvarig gentemot
              kunden enligt detta avtal, ska Xpektor Tech ABs totala maximala
              ansvar begränsas till det belopp som kunden betalat för produkten
              under det år då sådant ansvar uppstod.
            </p>
          </div>
          <div className="policy-section">
            <p className="section-title" id="terms-14">
              14. Avtalstid och uppsägning
            </p>
            <p>
              14.1 Detta avtal ska, om inte annat sägs upp enligt vad som anges
              i detta avsnitt 14, börja på ikraftträdandedatumet och ska
              fortsätta under licensperioden och kommer automatiskt att förnyas
              förutsatt att detta anges i OBF.
              <br />
              14.2 Utan att det påverkar andra rättigheter eller rättsmedel som
              parterna kan ha rätt till, kan endera parten säga upp detta avtal
              utan ansvar gentemot den andra och med omedelbar verkan om:
              <br />
              (a) den andra parten begår en väsentlig överträdelse av något av
              villkoren i detta avtal och (om en sådan överträdelse kan
              avhjälpas) underlåter att avhjälpa en sådan överträdelse inom
              trettio (30) dagar efter det att den parten skriftligen
              underrättats om överträdelsen; eller
              <br />
              (b) den andra parten försätts i likvidation, oavsett om den är
              obligatorisk eller frivillig, förklaras insolvent eller ett beslut
              fattas om att utse en förvaltare för att förvalta den andra
              partens angelägenheter, affärsverksamhet och egendom.
              <br />
              14.3 Xpektor Tech AB kan omedelbart säga upp detta avtal om kunden
              är eller blir en konkurrent till Xpektor Tech AB eller säljer,
              underlicensierar, tillhandahåller, avslöjar eller överför
              produkten till någon av Xpektor Tech ABs konkurrenter eller kunder
              (faktiska eller potentiella).
              <br />
              14.4 Vid uppsägning av detta avtal av någon anledning:
              <br />
              (a) alla kundens rättigheter och licenser som beviljats enligt
              detta avtal ska omedelbart upphöra;
              <br />
              (b) inom tio (10) dagar efter uppsägningen av detta avtal ska
              kunden göra sitt bästa för att permanent radera och/eller förstöra
              alla kopior av konfidentiell information under kundens kontroll;
              <br />
              (c) Parternas upplupna rättigheter vid uppsägningen, eller
              fortsättningen efter uppsägning av någon bestämmelse som
              uttryckligen anges för att överleva eller implicit efterlevande
              uppsägning, ska inte påverkas eller skadas.
              <br />
              (d) Xpektor Tech AB ska inte vara skyldigt att återbetala några
              avgifter som erhållits från kunden, med undantag för Xpektor Tech
              ABs uppsägning i enlighet med avsnitt 5.6 (iv); och
              <br />
              (e) Xpektor Tech AB har rätt att skicka in en faktura för
              eventuella utestående avgifter som omedelbart förfaller och ska
              betalas.
              <br />
              14.5 Uppsägning av detta avtal ska inte påverka några upplupna
              rättigheter eller rättsmedel och följande avsnitt ska förbli i
              kraft: 10, 11, 12, 13, 14, 15.5 och 15.6.
            </p>
          </div>
          <div className="policy-section">
            <p className="section-title" id="terms-15">
              15. Allmänna bestämmelser
            </p>
            <p>
              15.1. Om någon bestämmelse (eller del av en bestämmelse) i detta
              avtal skulle anses av någon domstol eller administrativt organ med
              behörig jurisdiktion vara ogiltig, ogenomförbar eller olaglig, ska
              ogiltigheten begränsas till en sådan bestämmelse (eller en del av
              en bestämmelse) och alla andra bestämmelser ska förbli i kraft.
              <br />
              15.2. Detta avtal, och alla dokument som det hänvisas till i det,
              utgör hela avtalet mellan parterna och ersätter alla tidigare
              arrangemang, överenskommelser eller avtal mellan dem som rör ämnet
              som omfattas.
              <br />
              15.3. Kunden får inte, utan föregående skriftligt medgivande från
              Xpektor Tech AB, tilldela, överföra, debitera, underkontraktera
              eller på annat sätt handla med alla eller någon av sina
              rättigheter eller skyldigheter enligt detta avtal.
              <br />
              15.4. Alla meddelanden som krävs enligt detta avtal ska vara
              skriftliga. Xpektor Tech AB kan också tillhandahålla meddelanden
              via Xpektor Tech ABs produkt.
              <br />
              15.5. Konstruktionen, tolkningen och utförandet av detta Avtal,
              och transaktionerna enligt det, ska regleras av och tolkas i
              enlighet med svensk lag, utan hänvisning till dess
              lagvalsprinciper.
              <br />
              15.6 Alla tvister som uppstår på grund av eller i samband med
              detta Avtal ska omfattas av svensk domstols exklusiva
              jurisdiktion.
            </p>
          </div>
        </div>
        <Footer />
      </Background>
    </div>
  );
}
