/* eslint-disable react/prop-types */
import Moment from 'moment';
import { useState, useEffect } from 'react';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import Module from './Module';
import TransactionTable from './Transactions/TransactionTable/TransactionTable';
import BankService from '../../../../Services/BankService';
import Dropdown from '../../../../Components/Dropdown/Dropdown';
import { DateRangePicker } from '../../../../Components/DatePicker/DatePicker';
import LoadingBar from '../../../../Components/LoadingBar/LoadingBar';
import DataPanel from '../../../../Components/DataPanel/DataPanel';
import BalanceBar from '../../../../Components/BalanceBar/BalanceBar';
import {
  FilterDropdown,
  ToggleButton,
  ClearButton,
} from './Transactions/FilterComponents/FilterComponents';
import TransactionDetails from './Transactions/TransactionDetails/TransactionDetails';

export default function Accounts({ client }) {
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [minDate, setMinDate] = useState('');
  const [maxDate, setMaxDate] = useState('');

  const [selectedAccount, setSelectedAccount] = useState('all');
  const [accountData, setAccountData] = useState([]);
  const [accountTransactions, setAccountTransactions] = useState([]);
  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [selectedBalanceAvailable, setSelectedBalanceAvailable] = useState(0);
  const [showTransactionDetails, setShowTransactionDetails] = useState(false);
  const [targetTransaction, setTargetTransaction] = useState({});

  const [transactionDates, setTransactionDates] = useState([]);
  const [transactionInsights, setTransactionInsights] = useState([]);
  const [paymentsIn, setPaymentsIn] = useState(0);
  const [paymentsOut, setPaymentsOut] = useState(0);
  const [industries, setIndustries] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [inOutFilters, setInOutFilters] = useState([]);
  const [currencyFilters, setCurrencyFilters] = useState([]);
  const [industryFilters, setIndustryFilters] = useState([]);

  const [paymentsInCurrencySums, setPaymentsInCurrencySums] = useState({});
  const [paymentsOutCurrencySums, setPaymentsOutCurrencySums] = useState({});
  const [exchangeRates, setExchangeRates] = useState({});
  const [convertedSums, setConvertedSums] = useState({
    in: { sum: 0, percentage: 0 },
    out: { sum: 0, percentage: 0 },
  });

  const [accountDropdownOpen, setAccountDropdownOpen] = useState(false);
  const [accountDropdownText, setAccountDropdownText] = useState('Alla konton');
  const [calendarDropdownOpen, setCalendarDropdownOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('');

  const [fetchingAccountData, setFetchingAccountData] = useState(false);

  const selectedTargetCurrency = 'SEK';

  function ClearFilters() {
    setInOutFilters([]);
    setCurrencyFilters([]);
    setIndustryFilters([]);
  }

  function SelectAccountTransactionsFrom(account, data) {
    const newArray = [];
    for (let i = 0; i < data.length; i += 1) {
      if (account === 'all' || account === data[i].accountId) {
        for (let j = 0; j < data[i].transactions.length; j += 1) {
          newArray.push(data[i].transactions[j]);
        }
      }
    }
    setSelectedTransactions(newArray);
    return newArray;
  }

  function SelectBalancesFrom(accountId, data) {
    if (accountId === 'all') {
      let balance = 0;
      let currency = '';
      for (let i = 0; i < data.length; i += 1) {
        balance += Number(data[i].balanceAvailable.amount);
        if (i !== 0) {
          if (
            data[i].balanceAvailable.currency !==
            data[i - 1].balanceAvailable.currency
          ) {
            currency = 'mixed';
          }
        } else {
          currency = data[i].balanceAvailable.currency;
        }
      }
      setSelectedBalanceAvailable({ amount: balance, currency });
    } else {
      setSelectedBalanceAvailable(
        data.find((account) => account.accountId === accountId).balanceAvailable
      );
    }
  }

  function GetSingleCurrencySum(currencySums, targetCurrency) {
    let sum = 0;
    Object.keys(currencySums).forEach((currency) => {
      if (currency === targetCurrency) {
        sum += currencySums[currency];
      } else {
        let valueInBase = currencySums[currency];
        if (currency !== exchangeRates.base) {
          const baseRate = exchangeRates.rates[currency];
          valueInBase = currencySums[currency] / baseRate;
        }
        const targetRate = exchangeRates.rates[targetCurrency];
        sum += valueInBase * targetRate;
      }
    });
    return sum;
  }

  function GenerateInsightsFrom(transactions) {
    if (transactions === null || transactions.length === 0) {
      setTransactionInsights([]);
      setPaymentsInCurrencySums([]);
      setPaymentsOutCurrencySums([]);
      setConvertedSums({
        in: { sum: 0, percentage: 0 },
        out: { sum: 0, percentage: 0 },
      });
      setCurrencies([]);
      setPaymentsIn(0);
      setPaymentsOut(0);
      return;
    }

    let salaryPayments = 0;
    let taxPayments = 0;
    let gamblingPayments = 0;
    let kfmPayments = 0;
    let debtPayments = 0;
    let bolagsverketPayments = 0;

    const newInsightsArray = [];
    const inSums = [];
    const outSums = [];

    for (let i = 0; i < transactions.length; i += 1) {
      if (transactions[i].creditDebitIndicator === 'DBIT') {
        const cur = transactions[i].transactionAmount.currency;
        if (outSums[cur] === undefined) {
          outSums[cur] = Number(transactions[i].transactionAmount.amount);
        } else {
          outSums[cur] += Number(transactions[i].transactionAmount.amount);
        }

        if (transactions[i].name === 'Skatteverket') {
          taxPayments += 1;
        }
        if (transactions[i].name === 'Salary') {
          salaryPayments += 1;
        }
        if (
          transactions[i].industries.some((industry) => industry === 'Gambling')
        ) {
          gamblingPayments += 1;
        }
        if (transactions[i].name === 'Bolagsverket') {
          bolagsverketPayments += 1;
        }
        if (transactions[i].name === 'Kronofogden') {
          kfmPayments += 1;
        } else if (
          transactions[i].industries.some(
            (industry) => industry === 'Debt Collectors'
          )
        ) {
          debtPayments += 1;
        }
      } else {
        const cur = transactions[i].transactionAmount.currency;
        if (inSums[cur] === undefined) {
          inSums[cur] = Number(transactions[i].transactionAmount.amount);
        } else {
          inSums[cur] += Number(transactions[i].transactionAmount.amount);
        }
      }
    }
    setPaymentsInCurrencySums(inSums);
    setPaymentsOutCurrencySums(outSums);
    if (Object.keys(exchangeRates).length !== 0) {
      const convertedInSum = GetSingleCurrencySum(
        inSums,
        selectedTargetCurrency
      );
      const convertedOutSum = GetSingleCurrencySum(
        outSums,
        selectedTargetCurrency
      );
      setConvertedSums({
        in: {
          sum: convertedInSum,
          percentage:
            (convertedInSum / (convertedInSum + convertedOutSum)) * 100,
        },
        out: {
          sum: convertedOutSum,
          percentage:
            (convertedOutSum / (convertedInSum + convertedOutSum)) * 100,
        },
      });
    }

    if (salaryPayments > 0 && taxPayments === 0) {
      newInsightsArray.push(
        'Under vald period finns det lön men ingen betalning till Skatteverket.'
      );
    }
    if (gamblingPayments > 0) {
      newInsightsArray.push('Finns spel.');
    }
    if (kfmPayments > 0) {
      newInsightsArray.push('Finns betalningar till Kronofogden.');
    }
    if (debtPayments > 0) {
      newInsightsArray.push('Finns inkasso.');
    }
    if (bolagsverketPayments > 0) {
      newInsightsArray.push('Finns betalningar till Bolagsverket.');
    }

    setTransactionInsights(newInsightsArray);
  }

  function FindUniqueDates(transactions) {
    const uniqueDates = [];
    for (let i = 0; i < transactions.length; i += 1) {
      const date = Moment(
        transactions[i].valueDate ?? transactions[i].bookingDate
      ).format('YYYY-MM-DD');
      if (uniqueDates.indexOf(date) === -1) {
        uniqueDates.push(date);
      }
    }
    setTransactionDates(uniqueDates);
  }

  function FindInOut(transactions) {
    let foundPaymentsIn = 0;
    let foundPaymentsOut = 0;
    for (let i = 0; i < transactions.length; i += 1) {
      if (transactions[i].creditDebitIndicator === 'DBIT') {
        foundPaymentsOut += 1;
      } else if (transactions[i].creditDebitIndicator === 'CRDT') {
        foundPaymentsIn += 1;
      }
    }
    setPaymentsIn(foundPaymentsIn);
    setPaymentsOut(foundPaymentsOut);
  }

  function FindCurrencies(transactions) {
    const foundCurrencies = [];
    for (let i = 0; i < transactions.length; i += 1) {
      const existingCurrency = foundCurrencies.find(
        (currency) =>
          currency.currency === transactions[i].transactionAmount.currency
      );
      if (existingCurrency === undefined) {
        foundCurrencies.push({
          currency: transactions[i].transactionAmount.currency,
          occurences: 1,
        });
      } else {
        existingCurrency.occurences += 1;
      }
    }
    setCurrencies(foundCurrencies);
  }

  function FindIndustries(transactions) {
    const uniqueIndustries = [];
    for (let i = 0; i < transactions.length; i += 1) {
      transactions[i].industries.forEach((industry) => {
        const existingUniqueIndustry = uniqueIndustries.find(
          (category) => category.name === industry
        );
        if (existingUniqueIndustry === undefined) {
          uniqueIndustries.push({ name: industry, occurences: 1 });
        } else {
          existingUniqueIndustry.occurences += 1;
        }
      });
    }
    setIndustries(uniqueIndustries);
  }

  function SelectAccount(account) {
    ClearFilters();
    setSelectedAccount(account);
    SelectAccountTransactionsFrom(account, accountTransactions);
    SelectBalancesFrom(account, accountData);
  }

  const [transactionFetchError, setTransactionFetchError] = useState(false);
  const getTransactions = async () => {
    setIsLoading(true);
    setLoadingText('Hämtar transaktioner...');

    await BankService.getTransactions(
      client.id,
      Moment(fromDate).format('YYYY-MM-DD'),
      Moment(toDate).format('YYYY-MM-DD')
    )
      .then((response) => {
        ClearFilters();
        setAccountTransactions(response.data);
        SelectAccountTransactionsFrom(selectedAccount, response.data);
        setTransactionFetchError(false);
        setIsLoading(false);
      })
      .catch(() => {
        setTransactionFetchError(true);
        setIsLoading(false);
      });
  };

  // ON FIRST LOAD
  useEffect(() => {
    setMaxDate(Moment.utc().local());
    setMinDate(Moment.utc().local().subtract(1, 'years'));
  }, []);

  // GET ACCOUNTS WHEN CLIENT HAS LOADED
  useEffect(() => {
    const getAccountData = async () => {
      setFetchingAccountData(true);

      await BankService.getLatestExchangeRates()
        .then((response) => {
          setExchangeRates(response.data);
        })
        .catch(() => {});

      await BankService.getBankAccounts(client.id)
        .then((response) => {
          if (response.data.earliestTransactionDate !== null) {
            setMinDate(Moment(response.data.earliestTransactionDate).utc());
          }
          setAccountData(response.data.accounts);
          SelectBalancesFrom('all', response.data.accounts);
        })
        .catch(() => {
          // reload client to get new connection status to check if the
          // failure is due to the bank connection having been broken.
        })
        .finally(() => {
          setFetchingAccountData(false);
        });
    };
    if (client.bankConnectionActive || client.hadBankConnection) {
      getAccountData();
    }
  }, [client]);

  // GET TRANSACTIONS WHEN ACCOUNTS HAVE BEEN FETCHED
  useEffect(() => {
    const newFromDate = Moment.utc().local().subtract(1, 'months');
    const newToDate = Moment.utc().local();
    setFromDate(newFromDate);
    setToDate(newToDate);

    const getTransactionData = async () => {
      await BankService.getTransactions(
        client.id,
        Moment(newFromDate).format('YYYY-MM-DD'),
        Moment(newToDate).format('YYYY-MM-DD')
      )
        .then((response) => {
          setAccountTransactions(response.data);
          SelectAccountTransactionsFrom('all', response.data);
        })
        .catch(() => {
          setTransactionFetchError(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    if (accountData.length !== 0) {
      getTransactionData();
    }
  }, [accountData]);

  function OnSetFromDate(newDate) {
    if (newDate > toDate) setToDate(newDate);
    setFromDate(newDate);
  }

  function OnSetToDate(newDate) {
    if (newDate < fromDate) setFromDate(newDate);
    setToDate(newDate);
  }

  const sortedDates = transactionDates.sort(
    (a, b) => new Date(b) - new Date(a)
  );

  function ToggleFilter(filter, filterType) {
    if (filterType === 'inout') {
      if (inOutFilters.indexOf(filter) !== -1) {
        setInOutFilters(
          inOutFilters.filter((item) => {
            return item !== filter;
          })
        );
      } else {
        setInOutFilters((oldArray) => [...oldArray, filter]);
      }
    }
    if (filterType === 'currency') {
      if (currencyFilters.indexOf(filter) !== -1) {
        setCurrencyFilters(
          currencyFilters.filter((item) => {
            return item !== filter;
          })
        );
      } else {
        setCurrencyFilters((oldArray) => [...oldArray, filter]);
      }
    }
    if (filterType === 'industry') {
      if (industryFilters.indexOf(filter) !== -1) {
        setIndustryFilters(
          industryFilters.filter((item) => {
            return item !== filter;
          })
        );
      } else {
        setIndustryFilters((oldArray) => [...oldArray, filter]);
      }
    }
  }

  const [filteredTransactions, setFilteredTransactions] = useState([]);
  useEffect(() => {
    const newFiltered = selectedTransactions.filter((transaction) => {
      return (
        (inOutFilters.length === 0 ||
          (transaction.creditDebitIndicator === 'DBIT' &&
            inOutFilters.some((filter) => filter === 'Utbetalningar')) ||
          (transaction.creditDebitIndicator === 'CRDT' &&
            inOutFilters.some((filter) => filter === 'Inbetalningar'))) &&
        (currencyFilters.length === 0 ||
          currencyFilters.some(
            (filter) => filter === transaction.transactionAmount.currency
          )) &&
        (industryFilters.length === 0 ||
          transaction.industries.some((industry) =>
            industryFilters.some((filter) => filter === industry)
          ))
      );
    });

    const availableInOut = selectedTransactions.filter((transaction) => {
      return (
        (currencyFilters.length === 0 ||
          currencyFilters.some(
            (filter) => filter === transaction.transactionAmount.currency
          )) &&
        (industryFilters.length === 0 ||
          transaction.industries.some((industry) =>
            industryFilters.some((filter) => filter === industry)
          ))
      );
    });

    const availableCurrency = selectedTransactions.filter((transaction) => {
      return (
        (inOutFilters.length === 0 ||
          (transaction.creditDebitIndicator === 'DBIT' &&
            inOutFilters.some((filter) => filter === 'Utbetalningar')) ||
          (transaction.creditDebitIndicator === 'CRDT' &&
            inOutFilters.some((filter) => filter === 'Inbetalningar'))) &&
        (industryFilters.length === 0 ||
          transaction.industries.some((industry) =>
            industryFilters.some((filter) => filter === industry)
          ))
      );
    });

    const availableIndustries = selectedTransactions.filter((transaction) => {
      return (
        (inOutFilters.length === 0 ||
          (transaction.creditDebitIndicator === 'DBIT' &&
            inOutFilters.some((filter) => filter === 'Utbetalningar')) ||
          (transaction.creditDebitIndicator === 'CRDT' &&
            inOutFilters.some((filter) => filter === 'Inbetalningar'))) &&
        (currencyFilters.length === 0 ||
          currencyFilters.some(
            (filter) => filter === transaction.transactionAmount.currency
          ))
      );
    });
    FindInOut(availableInOut);
    FindCurrencies(availableCurrency);
    FindIndustries(availableIndustries);

    FindUniqueDates(newFiltered);
    setFilteredTransactions(newFiltered);
    GenerateInsightsFrom(newFiltered);
  }, [inOutFilters, currencyFilters, industryFilters, selectedTransactions]);

  return (
    <Module>
      {!client.bankConnectionActive && !client.hadBankConnection ? (
        <p className="module-empty-text">Ingen bank kopplad.</p>
      ) : fetchingAccountData ? (
        <LoadingBar active status="Hämtar data..." relative />
      ) : Object.keys(accountData).length === 0 ? (
        <p className="module-empty-text">Kunde inte hämta konto data.</p>
      ) : (
        <>
          <Module.Nav>
            <Dropdown
              title={accountDropdownText}
              open={accountDropdownOpen}
              onClick={() => {
                setAccountDropdownOpen(!accountDropdownOpen);
              }}
              onClickOutside={() => setAccountDropdownOpen(false)}
            >
              <Dropdown.Item
                content="Välj alla konton"
                onClick={() => {
                  setAccountDropdownText('Alla konton');
                  SelectAccount('all');
                  setAccountDropdownOpen(false);
                }}
              />
              {accountData.map((data) => {
                return (
                  <Dropdown.Item
                    content={
                      <div className="account-item">
                        <p className="account-title">
                          {data.details.details ??
                            data.details.product ??
                            data.details.name ??
                            'Namnlöst konto'}
                        </p>
                        <div className="account-balance-row">
                          <p className="account-sum">
                            {Number(
                              Number(data.balanceAvailable.amount).toFixed(0)
                            )
                              .toLocaleString()
                              .replaceAll(',', ' ')}
                          </p>
                          <p className="account-currency">
                            {data.balanceAvailable.currency}
                          </p>
                        </div>
                      </div>
                    }
                    onClick={() => {
                      setAccountDropdownText(
                        data.details.details ??
                          data.details.product ??
                          data.details.name ??
                          'Namnlöst konto'
                      );
                      SelectAccount(data.accountId);
                      setAccountDropdownOpen(false);
                    }}
                    key={data.accountId}
                  />
                );
              })}
            </Dropdown>
            <Dropdown
              title={`Från ${Moment(fromDate).format(
                'YYYY-MM-DD'
              )} till ${Moment(toDate).format('YYYY-MM-DD')}`}
              titleIcon={faCalendar}
              open={calendarDropdownOpen}
              onClick={() => {
                setCalendarDropdownOpen(!calendarDropdownOpen);
              }}
              onClickOutside={() => setCalendarDropdownOpen(false)}
              right
            >
              <DateRangePicker
                fromDate={fromDate}
                toDate={toDate}
                setFromDate={(value) => {
                  OnSetFromDate(value);
                }}
                setToDate={(value) => {
                  OnSetToDate(value);
                }}
                minDate={minDate}
                maxDate={maxDate}
                onSubmit={() => {
                  getTransactions();
                  setCalendarDropdownOpen(false);
                }}
              />
            </Dropdown>
          </Module.Nav>
          <Module.KeyFigureSectionAccounts>
            <Module.KeyFigure
              title="Saldo"
              value={
                selectedBalanceAvailable.currency !== 'mixed'
                  ? selectedBalanceAvailable.amount
                  : 'Blandade valutor'
              }
              valueType={
                selectedBalanceAvailable.currency !== 'mixed' ? 'currency' : ''
              }
              currency={
                selectedBalanceAvailable.currency !== 'mixed'
                  ? selectedBalanceAvailable.currency
                  : ''
              }
            />
          </Module.KeyFigureSectionAccounts>
          <LoadingBar active={isLoading} status={loadingText} />
          <Module.TransactionContent>
            <DataPanel title="Filtrera" scroll>
              <ClearButton
                text="Rensa filter"
                onClick={() => ClearFilters()}
                disabled={
                  inOutFilters.length === 0 &&
                  currencyFilters.length === 0 &&
                  industryFilters.length === 0
                }
              />
              <FilterDropdown title="In / ut">
                <ToggleButton
                  text="Inbetalningar"
                  active={inOutFilters.some(
                    (filter) => filter === 'Inbetalningar'
                  )}
                  onClick={() => ToggleFilter('Inbetalningar', 'inout')}
                  count={paymentsIn}
                />
                <ToggleButton
                  text="Utbetalningar"
                  active={inOutFilters.some(
                    (filter) => filter === 'Utbetalningar'
                  )}
                  onClick={() => ToggleFilter('Utbetalningar', 'inout')}
                  count={paymentsOut}
                />
              </FilterDropdown>
              <FilterDropdown title="Valutor">
                {currencies.map((currency) => {
                  return (
                    <ToggleButton
                      text={currency.currency}
                      key={currency.currency}
                      active={currencyFilters.some(
                        (filter) => filter === currency.currency
                      )}
                      onClick={() =>
                        ToggleFilter(currency.currency, 'currency')
                      }
                      count={currency.occurences}
                    />
                  );
                })}
              </FilterDropdown>
              <FilterDropdown title="Industrier">
                {industries.map((industry) => {
                  return (
                    <ToggleButton
                      text={industry.name}
                      key={industry.name}
                      active={industryFilters.some(
                        (filter) => filter === industry.name
                      )}
                      onClick={() => ToggleFilter(industry.name, 'industry')}
                      count={industry.occurences}
                    />
                  );
                })}
              </FilterDropdown>
            </DataPanel>
            <TransactionTable
              inSums={paymentsInCurrencySums}
              outSums={paymentsOutCurrencySums}
              error={
                transactionFetchError === true
                  ? 'Kunde inte hämta transaktioner!'
                  : selectedTransactions.length === 0
                  ? 'Finns inga transaktioner'
                  : false
              }
            >
              {sortedDates.map((date) => {
                return (
                  <div key={date}>
                    <p className="transaction-date">{date}</p>
                    {filteredTransactions.map((data) => {
                      return (
                        Moment(data.valueDate ?? data.bookingDate).format(
                          'YYYY-MM-DD'
                        ) === date && (
                          <TransactionTable.Item
                            transaction={data}
                            key={data.id}
                            onClick={() => {
                              setTargetTransaction(data);
                              setShowTransactionDetails(true);
                            }}
                          />
                        )
                      );
                    })}
                  </div>
                );
              })}
            </TransactionTable>
            <Module.DataColumn>
              <DataPanel title="Insikter" scroll>
                {transactionInsights.map((data) => {
                  return <DataPanel.TriggerEntry name={data} key={data} />;
                })}
              </DataPanel>
              <BalanceBar
                leftPercentage={convertedSums.in.percentage}
                rightPercentage={convertedSums.out.percentage}
                leftSum={convertedSums.in.sum}
                rightSum={convertedSums.out.sum}
                sumSign={selectedTargetCurrency}
                leftColor="#AFDBF5"
                rightColor="#6E97DB"
                leftHeader="Inbetalningar"
                leftData={paymentsInCurrencySums}
                rightHeader="Utbetalningar"
                rightData={paymentsOutCurrencySums}
              />
            </Module.DataColumn>
          </Module.TransactionContent>
        </>
      )}
      {showTransactionDetails && (
        <TransactionDetails
          transaction={targetTransaction}
          handleClose={() => setShowTransactionDetails(false)}
        />
      )}
    </Module>
  );
}
