import './NotFound.scss';
import { useHistory } from 'react-router-dom';
import HomeNav from '../HomeNav';
import RoundedButton from '../../../Components/RoundedButton/RoundedButton';

export default function NotFound() {
  const history = useHistory();

  return (
    <div className="xpektor-home">
      <div className="not-found">
        <HomeNav />
        <div className="not-found-content">
          <h1 className="title">
            Den här sidan verkar ha gått på en liten budgetsmäll.
          </h1>
          <RoundedButton
            text="Tillbaka till startsidan"
            fillCyan
            onClick={() => history.push('/')}
          />
          <h1 className="not-found-sign">404</h1>
        </div>
      </div>
    </div>
  );
}
