import './ServiceStatusMessageModal.scss';
import { Button, Modal } from 'react-bootstrap';
import { useState } from 'react';
import {
  faCircleCheck,
  faCircleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  XpektorForm,
  FormInput,
  FormButton,
} from '../../../Components/XpektorForms/XpektorForms';
import Dropdown from '../../../Components/Dropdown/Dropdown';
import StatusService from '../../../Services/StatusService';

/* eslint-disable react/prop-types */
export default function ServiceStatusMessageModal({
  handleClose,
  onCreateMessage,
}) {
  const [creatingMessage, setCreatingMessage] = useState(false);

  const [severityDropdownOpen, setSeverityDropdownOpen] = useState(false);
  const severities = [
    { id: 0, description: 'Uppdatering', icon: faCircleCheck },
    { id: 1, description: 'Varning', icon: faCircleExclamation },
    { id: 2, description: 'Allvarligt', icon: faCircleExclamation },
  ];
  const [selectedSeverity, setSelectedSeverity] = useState({
    id: 2,
    description: 'Allvarligt',
    icon: faCircleExclamation,
  });

  const [messageTitle, setMessageTitle] = useState('');
  const [messageText, setMessageText] = useState('');

  const createMessage = async (event) => {
    event.preventDefault();

    setCreatingMessage(true);
    await StatusService.createServiceStatusMessage(
      messageTitle,
      messageText,
      selectedSeverity.id
    )
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        onCreateMessage();
      });
  };

  function validateForm() {
    return messageTitle.length > 0 && messageText.length > 0;
  }

  return (
    <Modal
      show
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="border-0 service-status-message-modal"
    >
      <Modal.Header>
        <Modal.Title>Skapa meddelande</Modal.Title>
        <Button className="btn-close" bsPrefix="button" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body className="border-0">
        <Dropdown
          title={selectedSeverity.description}
          componentIcon={
            <FontAwesomeIcon
              className={
                selectedSeverity.id === 0
                  ? 'ok'
                  : selectedSeverity.id === 1
                  ? 'warning'
                  : 'error'
              }
              icon={selectedSeverity.icon}
            />
          }
          onClick={() => setSeverityDropdownOpen(!severityDropdownOpen)}
          onClickOutside={() => setSeverityDropdownOpen(false)}
          open={severityDropdownOpen}
        >
          {severities.map((severity, index) => {
            return (
              <Dropdown.Item
                content={
                  <div className="severity-item">
                    <FontAwesomeIcon
                      icon={severity.icon}
                      className={
                        index === 0 ? 'ok' : index === 1 ? 'warning' : 'error'
                      }
                    />
                    <p>{severity.description}</p>
                  </div>
                }
                onClick={() => {
                  setSelectedSeverity(severity);
                  setSeverityDropdownOpen(false);
                }}
                key={severity.id}
              />
            );
          })}
        </Dropdown>
        <XpektorForm onSubmit={createMessage}>
          <FormInput
            type="text"
            label="Titel *"
            placeholder="Ex: Driftstörning"
            value={messageTitle}
            onChange={(e) => setMessageTitle(e.target.value)}
          />
          <FormInput
            type="text"
            label="Meddelande *"
            placeholder="Det här har gått fel"
            value={messageText}
            onChange={(e) => setMessageText(e.target.value)}
          />
          <FormButton
            text={creatingMessage ? 'Skapar...' : 'Skapa'}
            disabled={creatingMessage || !validateForm()}
          />
        </XpektorForm>
      </Modal.Body>
    </Modal>
  );
}
