/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import ReactCountryFlag from 'react-country-flag';
import Searchbar from '../../../Components/Searchbar/Searchbar';
import Dropdown from '../../../Components/Dropdown/Dropdown';

export default function BankAuthStep({
  completed,
  banks,
  countries,
  selectedCountry,
  onCountryClick,
  selectBank,
  error,
  errorText,
}) {
  const [searchQuery, setSearchQuery] = useState('');
  const [countryDropdownOpen, setCountryDropdownOpen] = useState(false);

  const popularBanks = ['Handelsbanken', 'Nordea', 'SEB', 'Svea', 'Swedbank'];

  const filteredBanks = searchQuery
    ? banks.filter((bank) => {
        return bank.name?.toLowerCase().includes(searchQuery?.toLowerCase());
      })
    : banks;

  // Sort by popular banks, prioritizing business banks.
  const sortedBanks = filteredBanks.sort((a, b) => {
    const aIsPopular = popularBanks.some((popularBank) => {
      return a.name.includes(popularBank);
    });
    const bIsPopular = popularBanks.some((popularBank) => {
      return b.name.includes(popularBank);
    });

    if (aIsPopular && !bIsPopular) {
      return -1;
    }
    if (bIsPopular && !aIsPopular) {
      return 1;
    }
    if (aIsPopular && bIsPopular) {
      if (a.psuType < b.psuType) return -1;
      if (a.psuType > b.psuType) return 1;
    }
    return 0;
  });

  return (
    <div className="auth-step">
      {completed ? (
        <div className="auth-step-completed-content">
          <FontAwesomeIcon
            className="auth-complete-icon"
            icon={faCircleCheck}
          />
          <p className="auth-complete-header">Banken är kopplad!</p>
        </div>
      ) : (
        <>
          <p className="auth-step-header">
            Koppla på <b>FÖRETAGETS</b> bankkonto
          </p>
          <p className="auth-step-description">
            Genom att koppla ditt <i>företagskonto</i> till Xpektor slipper du
            manuellt samla in och skicka bankutdrag. Xpektor hämtar automatiskt
            den information som behövs vilket underlättar och snabbar på
            hanteringen av din förfrågan.
          </p>
          {error ? (
            <div className="auth-step-error">
              <p>
                {errorText ??
                  'Något gick fel och det gick inte att koppla banken.'}
              </p>
            </div>
          ) : (
            ''
          )}
          <div className="bank-nav">
            <Searchbar
              placeholder="Sök bank"
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <Dropdown
              open={countryDropdownOpen}
              title={selectedCountry.name}
              componentIcon={
                <ReactCountryFlag countryCode={selectedCountry.code} svg />
              }
              onClick={() => setCountryDropdownOpen(!countryDropdownOpen)}
              onClickOutside={() => setCountryDropdownOpen(false)}
              right
            >
              {countries.map((country) => {
                return (
                  <Dropdown.Item
                    key={country.code}
                    content={
                      <div className="country-item">
                        <ReactCountryFlag countryCode={country.code} svg />
                        <p>{country.name}</p>
                      </div>
                    }
                    onClick={() => {
                      setCountryDropdownOpen(false);
                      onCountryClick(country);
                    }}
                  />
                );
              })}
            </Dropdown>
          </div>
          <div className="bank-grid">
            {sortedBanks.map((data) => {
              return (
                <div
                  className="bank-button"
                  onClick={() => selectBank(data)}
                  role="button"
                  tabIndex="0"
                  key={`${data.name} ${data.country} ${data.bic}`}
                >
                  <div className="button-content-container">
                    <p>{data.name}</p>
                    <img
                      className="bank-image"
                      src={data.logo}
                      alt="Bank logo"
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}
