import EnableBankingLogo from '../../../Images/Logos/White/Enable_banking_white.svg';
import FortnoxLogo from '../../../Images/Logos/White/fortnox_white.svg';
import VismaLogo from '../../../Images/Logos/White/Visma_spcs_white.svg';
import EkonomikaLogo from '../../../Images/Logos/White/Ekonomika_white.png';
import YourCFOLogo from '../../../Images/Logos/White/yourcfo_white.png';
import CategoriaLogo from '../../../Images/Logos/White/Categoria_white.png';
import AccountecLogo from '../../../Images/Logos/White/accountec_white.png';
import OPRLogo from '../../../Images/Logos/White/opr_white.svg';

export default function LogoSection() {
  return (
    <div className="section logo-section">
      <div className="logo-row">
        <img src={EnableBankingLogo} alt="company_logo" />
        <img src={FortnoxLogo} alt="company_logo" />
        <img src={VismaLogo} alt="company_logo" />
        <img src={EkonomikaLogo} alt="company_logo" />
        <img src={YourCFOLogo} alt="company_logo" />
        <img src={CategoriaLogo} alt="company_logo" />
        <img src={AccountecLogo} alt="company_logo" />
        <img src={OPRLogo} alt="company_logo" style={{ height: '48px' }} />
      </div>
      <p className="section-title">REKOMMENDATIONER</p>
      <div className="quotes">
        <div className="quote-pair-container">
          <div className="quote-container">
            <p className="section-text">
              {`"Xpektor sparar oss tid och minskar risken för fel, vilket ger oss
            mer utrymme att fokusera på värdeskapande rådgivning för våra
            kunder. Ett verktyg som gör vårt jobb både enklare och mer
            effektivt!"`}
            </p>
            <p className="author">- Categoria</p>
          </div>

          <div className="quote-container">
            <p className="section-text">
              {`"Med Xpektor får vi äntligen viktiga insikter i tid för att kunna
            agera och skapa mervärde samt en "automatiserad kollega" som lär
            våra juniora konsulter hur de kan börja agera rådgivare. Vi ser fram
            emot nästa steg i utvecklingen av produkten!"`}
            </p>
            <p className="author">- YourCFO</p>
          </div>
        </div>
        <div className="quote-pair-container">
          <div className="quote-container">
            <p className="section-text">
              {`"Ansökningsprocessen har blivit smidigare och snabbare från kundens perspektiv tack vare Xpektor. Kunden behöver inte längre skicka underlag till oss utan kan enkelt signera Xpektor med BankID. Detta sparar tid och gör processen mycket enklare för alla inblandade. Dessutom visar Xpektor varningssignaler om saker vi behöver vara mer observanta på, vilket gör det snabbare för oss att koncentrera oss på de viktigaste punkterna i datan. Xpektor har även hjälpt oss att upptäcka bedrägeriförsök, vilket är en stor fördel för vår verksamhet."`}
            </p>
            <p className="author">- OPR Finance</p>
          </div>

          <div className="quote-container">
            <p className="section-text">
              {`"Sedan vi började använda Xpektor har vi märkt betydande förbättringar inom flera viktiga områden som påverkar både vår dagliga verksamhet och långsiktiga produktivitet. Xpektor har gjort det möjligt för oss att effektivisera vårt handläggande av låneansökningar samt förbättrat kvalitén på våra kreditbeslut. Detta har lett till att vi använder våra resurser mer effektivt och påskyndar processen att handlägga respektive ärende."`}
            </p>
            <p className="author">- Kredithandläggare</p>
          </div>
        </div>
      </div>
    </div>
  );
}
