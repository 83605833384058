import './Footer.scss';
import ReactCountryFlag from 'react-country-flag';
import XpektorLogo from '../../../../Components/XpektorLogo/XpektorLogo';
import LinkedInIcon from '../../../../Images/Icons/Linkedin.svg';

/* eslint-disable react/prop-types */
export default function Footer({ darkMode }) {
  return (
    <div className={`home-footer ${darkMode ? 'home-footer-dark' : ''}`}>
      <div className="main-row">
        <div className="xpektor-column">
          <XpektorLogo darkMode={darkMode} />
          <p>
            Bedöm dina kunders riskprofil, identifiera nya affärsmöjligheter och
            fatta rätt beslut i realtid.
          </p>
        </div>
        <div className="link-columns">
          <div className="link-column">
            <p className="column-title">PRODUKT</p>
            <a href="/finance">Kreditgivare och bank</a>
            <a href="/advice">Redovisningsbyråer</a>
          </div>
          <div className="link-column">
            <p className="column-title">XPEKTOR</p>
            <a href="/about">Om oss</a>
            <a href="/contact">Kontakta oss</a>
            <a href="/why">Varför Xpektor</a>
          </div>
        </div>
      </div>
      <div className="end-row">
        <div className="interactable-row">
          <div className="country-select">
            <ReactCountryFlag countryCode="SE" svg />
            <p>Sverige</p>
          </div>
          <div className="links">
            <a href="/privacypolicy">Integritet</a>
            <a href="/termsandconditions">Villkor</a>
          </div>
        </div>
        <p>© 2024 Xpektor AB. Alla rättigheter förbehållna.</p>
        <div className="social-media">
          <button
            type="button"
            onClick={() =>
              window.open(
                'https://www.linkedin.com/company/xpektortech/',
                '_blank',
                'noopener,noreferrer'
              )
            }
          >
            <img src={LinkedInIcon} alt="In" />
          </button>
        </div>
      </div>
    </div>
  );
}
