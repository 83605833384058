/* eslint-disable react/prop-types */
import RoundedButton from '../../../Components/RoundedButton/RoundedButton';
import { OpenDemoLink } from '../../../Components/Utilities/HelperFunctions';

export default function BannerSection({
  sectionTitle,
  title,
  text,
  buttonText,
}) {
  return (
    <div className="section banner-section">
      <div className="background">
        <p className="section-title">{sectionTitle}</p>
        <h2>{title}</h2>
        <p className="section-text">{text}</p>
        <RoundedButton text={buttonText} fillCyan onClick={OpenDemoLink} />
      </div>
    </div>
  );
}
