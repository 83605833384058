import './Policy.scss';
import HomeNav from '../HomeNav';
import Footer from '../Components/Footer/Footer';
import Background from '../Components/Background';

export default function PrivacyPolicy() {
  return (
    <div className="xpektor-home">
      <div className="policy-intro">
        <HomeNav />
        <div className="intro-section">
          <p className="section-title">Sverige</p>
          <h1>Integritetspolicy</h1>
          <p className="section-text">
            Senast uppdaterad: 05 Dec 2024 - Version: 1.2
          </p>
        </div>
      </div>
      <Background color1="white">
        <div className="policy-wrapper">
          <div className="policy-header">
            <p className="policy-title">Integritetspolicy</p>
            <p>Senast uppdaterad: 05 Dec 2024</p>
            <p className="policy-description">
              {`Denna integritetspolicy förklarar hur Xpektor Tech AB (nedan "vi",
          "oss" eller "företaget") samlar in, använder, skyddar och hanterar
          dina personuppgifter enligt gällande lagstiftning, inklusive EU:s
          allmänna dataskyddsförordning (GDPR).`}
            </p>
          </div>
          <div className="policy-section">
            <p className="section-title">Tjänsten Xpektor</p>
            <p>
              {`Xpektor utgör ett komplement till befintliga affärssystem, för realtidsläsning av både bokförd
                och ej bokförd data, inklusive personuppgifter. Dessa behandlingar styrs i allt väsentligt av
                våra kunder, i syften de definierar inom sin verksamhet och genom insamling av uppgifter
                som de väljer.`}
            </p>
            <p>
              {`När du blir inbjuden till att dela dina uppgifter med Xpektor-plattformen av en av våra kunder
                agerar vi biträde och behandlar dessa uppgifter enligt specifik instruktion och inte i egna
                syften. Detta ser du genom att deras namn syns längst upp inom tjänsten. Detta regleras
                genom (biträdes)avtal mellan Xpektor och respektive organisation som köpt in vår tjänst, i
                enlighet med de formella krav som följer av EU:s allmänna dataskyddsförordning (GDPR).
                Har du frågor kring behandlingar av uppgifter som samlats in genom vår plattform på
                uppdrag av en av våra kunder hänvisar vid dig till dem för svar på frågor och begäranden.
                `}
            </p>
            <p>
              När du själv köper tjänsten av Xpektor kan du läsa om hur vi
              behandlar dina personuppgifter, nedan.
            </p>
          </div>
          <div className="toc-section">
            <a href="#privacy-1">1. Insamlade uppgifter</a>
            <a href="#privacy-2">2. Laglig grund för behandling</a>
            <a href="#privacy-3">3. Användning av uppgifter</a>
            <a href="#privacy-4">4. Datadelning</a>
            <a href="#privacy-5">5. Dataskydd</a>
            <a href="#privacy-6">6. Dina rättigheter</a>
            <a href="#privacy-7">7. Ändringar i integritetspolicyn</a>
            <a href="#privacy-8">8. Kontakt</a>
          </div>

          <div className="policy-section">
            <p className="section-title" id="privacy-1">
              1. Insamlade uppgifter
            </p>
            <p>{`Vi samlar in olika typer av personuppgifter för att kunna tillhandahålla och 
            förbättra våra tjänster. De typer av uppgifter vi kan samla in inkluderar:`}</p>
            <ul>
              <li>
                Kontaktinformation såsom namn, adress, e-postadress,
                telefonnummer.
              </li>
              <li>
                Identifieringsuppgifter såsom personnummer eller
                företagsregistreringsnummer.
              </li>
              <li>Ekonomisk information såsom betalningsuppgifter.</li>
              <li>
                Teknisk information såsom IP-adress, webbläsartyp,
                enhetsinformation.
              </li>
              <li>
                Användaruppgifter såsom inloggningsinformation,
                användarinställningar.
              </li>
            </ul>
          </div>
          <div className="policy-section">
            <p className="section-title" id="privacy-2">
              2. Laglig grund för behandling
            </p>
            <p>
              Vi behandlar dina personuppgifter på följande lagliga grunder:
            </p>
            <ul>
              <li>
                För att uppfylla avtal eller vidta åtgärder på begäran före
                avtalsslut.
              </li>
              <li>För att uppfylla en rättslig förpliktelse.</li>
              <li>Med ditt samtycke när så krävs enligt lag.</li>
              <li>
                För att tillgodose våra berättigade intressen, såsom att
                förbättra och marknadsföra våra tjänster.
              </li>
            </ul>
          </div>
          <div className="policy-section">
            <p className="section-title" id="privacy-3">
              3. Användning av uppgifter
            </p>
            <p>Vi använder insamlade uppgifter för följande ändamål:</p>
            <ul>
              <li>För att tillhandahålla och underhålla våra tjänster.</li>
              <li>
                För att kommunicera med dig angående dina förfrågningar, köp
                eller supportärenden.
              </li>
              <li>
                För att anpassa och förbättra våra tjänster samt utveckla nya
                funktioner.
              </li>
              <li>
                För att skicka relevant information, erbjudanden eller
                marknadsföring baserat på ditt samtycke eller våra berättigade
                intressen.
              </li>
            </ul>
          </div>
          <div className="policy-section">
            <p className="section-title" id="privacy-4">
              4. Datadelning
            </p>
            <p>
              Vi delar endast dina personuppgifter med tredje part när det är
              nödvändigt för att tillhandahålla våra tjänster eller enligt
              lagliga förpliktelser. Vi säkerställer att sådana tredje parter
              följer tillräckliga säkerhetsåtgärder för att skydda dina
              uppgifter.
            </p>
          </div>
          <div className="policy-section">
            <p className="section-title" id="privacy-5">
              5. Dataskydd
            </p>
            <p>
              Vi vidtar lämpliga tekniska och organisatoriska åtgärder för att
              skydda dina personuppgifter mot obehörig åtkomst, ändring,
              spridning eller förlust.
            </p>
          </div>
          <div className="policy-section">
            <p className="section-title" id="privacy-6">
              6. Dina rättigheter
            </p>
            <p>
              Du har rätt att begära tillgång till, rättelse av, eller radering
              av dina personuppgifter. Du har även rätt att invända mot viss
              behandling av dina uppgifter eller begära begränsning av
              behandlingen. Kontakta oss via nedan angivna kontaktuppgifter för
              att utöva dina rättigheter.
            </p>
          </div>
          <div className="policy-section">
            <p className="section-title" id="privacy-7">
              7. Ändringar i integritetspolicyn
            </p>
            <p>
              Vi kan komma att uppdatera vår integritetspolicy och meddela dig
              om väsentliga ändringar. Senaste uppdateringsdatum för policyn
              anges nedan.
            </p>
          </div>
          <div className="policy-section">
            <p className="section-title" id="privacy-8">
              8. Kontakt
            </p>
            <p>
              Om du har frågor angående vår integritetspolicy eller vår
              hantering av dina uppgifter, kontakta oss på:
            </p>
            <p>
              Xpektor Tech AB
              <br />
              Adress: Hvitfeldtsplatsen 7
              <br />
              Telefon: +46 769-424744
              <br />
              E-post: daniel@xpektor.se
            </p>
          </div>
          <p>---</p>
          <p>{`Denna integritetspolicy är utformad för att vara i linje med svenska lagar och 
            EU:s dataskyddsförordning (GDPR). Den tar hänsyn till de olika typer av 
            personuppgifter som Xpektor Tech AB kan hantera och beskriver tydligt hur vi 
            använder och skyddar denna information.`}</p>
        </div>
        <Footer />
      </Background>
    </div>
  );
}
