/* eslint-disable react/prop-types */
import './CardButton.scss';
import { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function CardButton({
  onClick,
  icon,
  disabled,
  styleClass,
  image,
  imageAlt,
  textHeader,
  textContent,
  expandedContent,
}) {
  const [expanded, setExpanded] = useState(false);
  const [hover, setHover] = useState(false);
  const contentRef = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        expandedContent &&
        contentRef.current &&
        !contentRef.current.contains(event.target)
      ) {
        setExpanded(false);
      }
    }

    // Bind
    document.addEventListener('click', handleClickOutside);
    return () => {
      // dispose
      document.removeEventListener('click', handleClickOutside);
    };
  }, [contentRef, expandedContent]);

  function OnClickWrapper() {
    if (!disabled && !expanded) {
      if (onClick) onClick();
      if (expandedContent) setExpanded(!expanded);
    }
  }

  function Hover() {
    if (!disabled) setHover(true);
  }

  function UnHover() {
    setHover(false);
  }

  return (
    <div
      className={`card-button ${styleClass ?? ''} ${
        expanded ? 'card-button-expanded' : ''
      } ${hover && !expanded ? 'card-button-hover' : ''}`}
      onMouseEnter={Hover}
      onMouseLeave={UnHover}
      onClick={OnClickWrapper}
      onKeyDown={OnClickWrapper}
      role="button"
      disabled={disabled || expanded}
      ref={contentRef}
    >
      <div className="main-content-row">
        {image && <img className="partner-logo" src={image} alt={imageAlt} />}
        <span className="text-container">
          <p className="text-header">{textHeader}</p>
          <p className="text-content">{textContent}</p>
        </span>
        {!disabled ? <FontAwesomeIcon className="icon" icon={icon} /> : ''}
      </div>
      {expanded ? (
        <div className="expanded-content">{expandedContent}</div>
      ) : (
        ''
      )}
    </div>
  );
}
