/* eslint-disable react/prop-types */
import './Background.scss';

export default function Background({ children, color1, color2, end }) {
  return (
    <div
      className={`background-section ${end ? 'background-end' : ''}`}
      style={
        !end && color1
          ? color2
            ? { backgroundImage: `linear-gradient(${color1}, ${color2})` }
            : { backgroundColor: color1 }
          : {}
      }
    >
      {children}
    </div>
  );
}

export function BackgroundElement({ img, yPos }) {
  return (
    <img
      className="background-element"
      src={img}
      alt="background-element"
      style={yPos ? { transform: `translateY(${yPos}px)` } : {}}
    />
  );
}
