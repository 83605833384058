import jwt from 'jwt-decode';

const getLocalRefreshToken = () => {
  const jsonToken = localStorage.getItem('monkey-token');
  if (!jsonToken) return null;

  const token = JSON.parse(jsonToken);
  return token?.refreshToken;
};

const getLocalAccessToken = () => {
  const jsonToken = localStorage.getItem('monkey-token');
  if (!jsonToken) return null;

  const token = JSON.parse(jsonToken);
  return token?.token;
};

const getLocalUser = () => {
  const jsonToken = localStorage.getItem('monkey-token');
  if (!jsonToken) return null;

  const token = JSON.parse(jsonToken);
  return token?.user;
};

const getExp = () => {
  const jsonToken = localStorage.getItem('monkey-token');
  if (!jsonToken) return null;

  const token = JSON.parse(jsonToken);
  const decodedToken = jwt(token?.token);
  return decodedToken.exp * 1000;
};

const setToken = (token, tokenNumber) => {
  localStorage.setItem('monkey-token', JSON.stringify(token));
  localStorage.setItem('monkey-token-number', !tokenNumber ? 1 : tokenNumber);
};

const removeToken = () => {
  localStorage.removeItem('monkey-token');
};

const MonkeyTokenService = {
  getLocalRefreshToken,
  getLocalAccessToken,
  getLocalUser,
  setToken,
  removeToken,
  getExp,
};

export default MonkeyTokenService;
