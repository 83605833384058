/* eslint-disable react/prop-types */
import './ClientSettings.scss';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import UserService from '../../../../Services/UserService';
import CustomerService from '../../../../Services/CustomerService';
import ClientService from '../../../../Services/ClientService';
import ClientTable from './ClientTable/ClientTable';
import XpektorButton from '../../../../Components/XpektorButton/XpektorButton';
import HandleUserClientAccess from './Modals/HandleUserClientAccess';
import EditNotificationModal from './Modals/EditNotificationModal';
import CreateClientPopUp from './Modals/CreateClientPopUp';
import AuthenticateClient from './AuthenticateClient';
import DeleteModal from './DeleteModal';
import CreateAuthLinkModal from '../../Modals/CreateAuthLinkModal';

export default function ClientSettings() {
  const [clients, setClients] = useState([]);

  const [showUserAccessModal, setShowUserAccessModal] = useState(false);
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [showEditClientModal, setShowEditClientModal] = useState(false);
  const [showCreateClientModal, setShowCreateClientModal] = useState(false);

  const [showAuthClient, setShowAuthClient] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [currentUser, setCurrentUser] = useState();
  const [clientToEdit, setClientToEdit] = useState();
  const [customerId, setCustomerId] = useState();
  const [action, setAction] = useState();
  const { search } = useLocation();

  const [order, setOrder] = useState('asc');

  const sortByName = () => {
    if (order === 'asc') {
      clients.sort((a, b) => {
        const nameA = a.name?.toUpperCase();
        const nameB = b.name?.toUpperCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      setOrder('desc');
    } else if (order === 'desc') {
      clients.sort((a, b) => {
        const nameA = a.name?.toUpperCase();
        const nameB = b.name?.toUpperCase();

        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      });
      setOrder('asc');
    }
  };

  useEffect(() => {
    const getData = async () => {
      const user = await UserService.getCurrentUser();
      setCurrentUser(user);
      await CustomerService.getClients(user.customerId)
        .then((response) => {
          setClients(response.data.data);
          setCustomerId(user.customerId);
        })
        .catch(() => {});
    };
    const decodeAction = async () => {
      const queryAction = new URLSearchParams(search).get('action');
      const clientId = new URLSearchParams(search).get('clientId');
      if (!queryAction) return;

      let client = null;
      if (clientId) {
        client = await ClientService.getClient(clientId);
        setClientToEdit(client.data);
      }
      if (queryAction === 'changeClientAccess') {
        setShowUserAccessModal(true);
      } else if (queryAction === 'createClient') {
        setShowAuthClient(true);
      }
    };
    decodeAction();
    getData();
  }, [search]);

  return (
    <div className="client-settings-container">
      <div className="client-settings-header">
        <XpektorButton
          content="Lägg till klient"
          onClick={() => {
            setAction('createClient');
            setClientToEdit([]);
            setShowCreateClientModal(true);
          }}
        />
      </div>
      <ClientTable sortByName={sortByName} order={order}>
        {clients.map((data) => {
          return (
            <ClientTable.Item
              client={data}
              key={data.id}
              onNotificationClick={() => {
                setClientToEdit(data);
                setShowNotificationModal(true);
              }}
              onUserAccessClick={() => {
                setClientToEdit(data);
                setShowUserAccessModal(true);
              }}
              onEditClick={() => {
                setClientToEdit(data);
                setAction('updateClient');
                setShowEditClientModal(true);
              }}
              onDeleteClick={() => {
                setClientToEdit(data);
                setShowDeleteModal(true);
              }}
            />
          );
        })}
      </ClientTable>
      {showCreateClientModal && (
        <CreateAuthLinkModal
          handleClose={() => setShowCreateClientModal(false)}
        />
      )}

      {showNotificationModal && (
        <EditNotificationModal
          handleClose={() => setShowNotificationModal(false)}
          client={clientToEdit}
          customerId={currentUser.customerId}
        />
      )}
      {showUserAccessModal && (
        <HandleUserClientAccess
          handleClose={() => setShowUserAccessModal(false)}
          client={clientToEdit}
          customerId={currentUser.customerId}
        />
      )}
      {showEditClientModal && (
        <CreateClientPopUp
          handleClose={() => setShowEditClientModal(false)}
          action={action}
          clientToEdit={clientToEdit}
        />
      )}
      {showAuthClient && (
        <AuthenticateClient handleClose={() => setShowAuthClient(false)} />
      )}

      {showDeleteModal && (
        <DeleteModal
          show={showDeleteModal}
          handleClose={() => setShowDeleteModal(false)}
          action={action}
          clientToEdit={clientToEdit}
          customerId={customerId}
        />
      )}
    </div>
  );
}
