import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Alert, CircularProgress } from '@mui/material';
import ClientService from '../../../../Services/ClientService';
import CreateClientPopUp from './Modals/CreateClientPopUp';
import XpektorButton from '../../../../Components/XpektorButton/XpektorButton';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function AuthenticateClient(handleClose) {
  const [processNumber, setProcessNumber] = useState(1);
  const [showCreateClient, setShowCreateClient] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [clientInfo, setClientInfo] = useState([]); // To be removed after we stop returning client when authenticating.
  const { search } = useLocation();
  // eslint-disable-next-line no-return-assign
  const cleanUrl = () => (window.location = '/settings/clients');

  /* eslint-disable no-console */
  useEffect(() => {
    const authenticateClient = async () => {
      // eslint-disable-next-line @typescript-eslint/no-shadow

      const errorType = new URLSearchParams(search).get('error_type');
      if (errorType !== null) {
        const errorDescription = new URLSearchParams(search).get(
          'error_description'
        );

        setErrorMessage(
          `Något gick fel och det gick inte att koppla bokföringssystemet. ${
            errorDescription !== null
              ? `Felmeddelande: ${errorDescription}`
              : ''
          }`
        );
        setProcessNumber(3);
        return;
      }

      const action = new URLSearchParams(search).get('action');
      if (action === 'createClient') {
        const authCode = new URLSearchParams(search).get('authCode');
        const partnerId = new URLSearchParams(search).get('partnerId');

        await ClientService.authenticateAccounting(
          authCode,
          partnerId !== null ? Number(partnerId) : null,
          null
        )
          .then((response) => {
            setClientInfo(response.data);
            setProcessNumber(2);
          })
          .catch(() => {
            setErrorMessage(
              'Något gick fel och det gick inte att koppla bokföringssystemet.'
            );
            setProcessNumber(3);
          });
      }
    };

    authenticateClient();
  }, [search]);
  return (
    <>
      <Modal
        show
        onHide={cleanUrl}
        backdrop="static"
        keyboard={false}
        className="border-0"
      >
        <Modal.Header className="border-0">
          <Modal.Title>Skapa kontakt</Modal.Title>
          <Button className="btn-close" bsPrefix="button" onClick={cleanUrl} />
        </Modal.Header>
        {processNumber === 1 ? (
          <Modal.Body className="border-0">
            <div className="text-center">
              <CircularProgress />
            </div>
            <br />
          </Modal.Body>
        ) : processNumber === 2 ? (
          <Modal.Body className="border-0">
            <Alert severity="success">
              Bokföringssystemet är kopplat, verifiera klienten nedan!
            </Alert>
            <br />
            <div className="d-flex justify-content-between">
              <XpektorButton
                content="Verifiera klienten"
                onClick={() => {
                  setShowCreateClient(true);
                }}
              />
            </div>
          </Modal.Body>
        ) : processNumber === 3 ? (
          <Modal.Body className="border-0">
            <Alert severity="error">{`${errorMessage}`}</Alert>
            <br />
            <XpektorButton content="Stäng" onClick={cleanUrl} />
          </Modal.Body>
        ) : (
          ''
        )}
      </Modal>

      {showCreateClient && (
        <CreateClientPopUp
          show={showCreateClient}
          handleClose={() => setShowCreateClient(false)}
          clientToEdit={clientInfo}
          action="createClient"
        />
      )}
    </>
  );
}
