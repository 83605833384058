/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-undef */
import './ConnectAccountingModal.scss';
import { Button, Modal } from 'react-bootstrap';
import { useState } from 'react';
import {
  faArrowRight,
  faArrowUpRightFromSquare,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AuthService from '../../../Services/AuthService';
import FortnoxLogo from '../../../Images/Logos/fortnox-svg.svg';
import VismaLogo from '../../../Images/Logos/Visma_spcs_logo.svg';
import CardButton from '../../../Components/CardButton/CardButton';

/* eslint-disable react/prop-types */
// eslint-disable-next-line react/prop-types
export default function ConnectAccountingModal({ handleClose, authLevel }) {
  const redirectToFortnox = async () => {
    await AuthService.getAuthUrlFortnox(
      2, // Create client
      null,
      0 // Create client
    ).then((response) => {
      if (response) {
        window.location.replace(response);
      }
    });
  };
  const redirectToVisma = async () => {
    await AuthService.getAuthUrlVisma(
      2, // Create client
      null,
      0 // Create client
    ).then((response) => {
      if (response) {
        window.location.replace(response);
      }
    });
  };
  return (
    <Modal
      show
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="connect-accounting-modal"
    >
      <Modal.Header className="border-0">
        <Button className="btn-close" bsPrefix="button" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body className="border-0">
        <p className="connect-title">Koppla klient med ett bokföringssystem.</p>
        <p className="connect-description">
          Här kan du direkt koppla en klient med ett bokföringssystem om du har
          åtkomst till företaget.
        </p>
        <button
          type="button"
          className="auth-step-help-link"
          onClick={() =>
            window.open('/tutorials', '_blank', 'noopener,noreferrer')
          }
        >
          <p>Läs mer om integrationslicenser</p>
          <FontAwesomeIcon
            className="auth-step-help-link-icon"
            icon={faArrowUpRightFromSquare}
          />
        </button>
        <div className="integration-options">
          <CardButton
            textHeader="Fortnox"
            textContent="Kräver en integrationslicens i Fortnox"
            icon={faArrowRight}
            image={FortnoxLogo}
            onClick={redirectToFortnox}
            styleClass="integration-partner-button-content"
          />
          <CardButton
            textHeader="Visma"
            textContent="Kräver en integrationslicens i Visma"
            icon={faArrowRight}
            image={VismaLogo}
            onClick={redirectToVisma}
            styleClass="integration-partner-button-content"
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}
