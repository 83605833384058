import './XpektorLogo.scss';

/* eslint-disable react/prop-types */
export default function XpektorLogo({ target, darkMode }) {
  return (
    <a
      className={`xpektor-clickable-logo ${
        darkMode ? 'xpektor-clickable-logo-dark' : ''
      }`}
      href={target ?? '/'}
    >
      Xpektor
    </a>
  );
}
