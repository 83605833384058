import './RoundedButton.scss';

/* eslint-disable react/prop-types */
export default function RoundedButton({ text, onClick, fillCyan, fillBlue }) {
  return (
    <button
      type="button"
      className={`rounded-button ${
        fillCyan
          ? 'rounded-button-fill-cyan'
          : fillBlue
          ? 'rounded-button-fill-blue'
          : ''
      }`}
      onClick={onClick}
    >
      {text}
    </button>
  );
}
