import './Footer.scss';

export default function Footer() {
  /* const tooltipContent = () => {
    return (
      <div className="contact-container">
        <div className="column">
          <p>Hanna Gårdmark</p>
          <p>CEO</p>
          <p>+46 720 142 474</p>
          <p>hanna@xpektor.se</p>
        </div>
        <div className="column">
          <p>Daniel Eremar</p>
          <p>Head of sales</p>
          <p>+46 769 424 744</p>
          <p>daniel@xpektor.se</p>
        </div>
      </div>
    );
  }; */
  return (
    <div className="app-footer-wrapper">
      <div className="footer-content">
        <a
          className="link"
          href="/contact"
          target="_blank"
          rel="noopener noreferrer"
        >
          Kontakta oss
        </a>
        <div className="trademark">
          <p>© 2024 All rights reserved</p>
        </div>
      </div>
    </div>
  );
}
