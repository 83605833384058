import { useHistory } from 'react-router-dom';
import HomeNav from './HomeNav';
import Footer from './Components/Footer/Footer';
import RoundedButton from '../../Components/RoundedButton/RoundedButton';
import HelpOfferSection from './Components/HelpOfferSection';
import SpiderWebSection from './Components/SpiderWebSection';
import BannerSection from './Components/BannerSection';
import Background, { BackgroundElement } from './Components/Background';
import { OpenDemoLink } from '../../Components/Utilities/HelperFunctions';

// Media
import SwirlyLines2 from '../../Images/Home/swirly_lines_2.png';
// import SwarmWave from '../../Images/Home/swarm_wave.png';
import HelpOffer2 from '../../Images/Home/help_offer_2.png';
import FinanceProduct from '../../Images/Home/product_score.png';
import FinanceWeb from '../../Images/Home/xpektor_web_finance.svg';

export default function Finance() {
  const history = useHistory();

  return (
    <div className="xpektor-home">
      <div className="other-intro finance-intro">
        <HomeNav />
        <div className="section intro-section">
          <p className="section-title">Finance</p>
          <h1>
            Bank & <br />
            Kreditgivare
          </h1>
          <div className="button-row">
            <RoundedButton
              text="Läs mer"
              fillCyan
              onClick={() => {
                history.push('/why');
              }}
            />
            <RoundedButton text="Boka samtal med oss" onClick={OpenDemoLink} />
          </div>
        </div>
      </div>
      <Background color1="white">
        <div className="intro-row-section finance-intro-row">
          <h3>Bedöm risker och möjligheter i realtid med Xpektor</h3>
          <p className="section-text">
            Med Xpektor får du morgondagens resultat redan idag. Bedöm dina
            kunders riskprofil, få en bättre och effektivare
            kreditgivningsprocess och identifiera nya affärsmöjligheter - allt i
            realtid med vår skräddarsydda mjukvara.
          </p>
        </div>
      </Background>
      <Background color1="#0316F3">
        <div className="section post-intro-section">
          <h1>Ta din kreditprocess till en helt ny nivå</h1>
          <div className="post-intro-content">
            <div className="image-column">
              <img src={FinanceProduct} alt="product_image" />
            </div>
            <div className="text-column">
              <h3>
                Låt oss hjälpa dig ta snabbare och bättre kreditbeslut med
                betydligt lägre risk.
              </h3>
              <p className="section-text">
                Med Xpektor får du som kreditgivare tillgång till dina kunders
                bokföring, kontotransaktioner, skattekonto,
                myndighetsinformation och mycket mer - allt i realtid. Vi
                effektiviserar hela din kreditgivningsprocess och med hjälp av
                vår automatiserade riskanalys får du marknadens bästa och mest
                innovativa beslutsstöd för kreditbedömning baserat på hundratals
                olika parametrar. Säg hejdå till utdaterad information och
                tidskrävande manuella processer
              </p>
              <RoundedButton text="Boka demo" fillCyan onClick={OpenDemoLink} />
            </div>
          </div>
        </div>
      </Background>
      <Background color1="#000B4F" color2="#010024">
        <HelpOfferSection
          image={HelpOffer2}
          title="Xpektor hjälper dig fatta trygga och säkra kreditbeslut snabbare"
          points={[
            'Effektivisera hela beslutprocessen genom automatiska insikter, från ansökan till beslut',
            'Gör robustare kreditbedömningar och sänk din kreditrisk',
            'Bevilja lån snabbare och öka lånevolymen på ett tryggt sätt',
            'Säkerställ trygghet och kontroll genom att upptäcka risker i tid',
          ]}
        />
        <div className="section-with-element">
          <SpiderWebSection
            title="Hur fungerar det?"
            text="Xpektor är en unik SaaS-lösning där kunden själv onboardar sig genom en länk och kopplar på affärssystem, bank, skatteverk och bolagsverk. Genom dessa vertikaler arbetar Xpektor fram alla analyser och kan snabbt räkna ut om kunden har möjlighet att betala tillbaka, är bedräglig och ger indikationer på penningtvätt."
            image={FinanceWeb}
          />
          <BackgroundElement img={SwirlyLines2} yPos={-400} />
        </div>
      </Background>
      <Background end>
        <BannerSection
          sectionTitle="TESTA XPEKTOR KOSTNADSFRITT"
          title="Att ta beslut i realtid har aldrig varit enklare och säkrare"
          text="Börja spara tid och minska risk redan idag, du får alltid prova kostnadsfritt i 30 dagar. Med Xpektor får du full kontroll över dina kunder utan manuell handpåläggning."
          buttonText="Starta idag"
        />
        <Footer darkMode />
      </Background>
    </div>
  );
}
