import './ResetPassword.scss';
import { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import UserService from '../../../Services/UserService';
import {
  XpektorForm,
  FormInput,
  FormButton,
} from '../../../Components/XpektorForms/XpektorForms';
import XpektorLogo from '../../../Components/XpektorLogo/XpektorLogo';
import XpektorButton from '../../../Components/XpektorButton/XpektorButton';

export default function ResetPassword() {
  const { search } = useLocation();
  const history = useHistory();

  const [token, setToken] = useState('');
  const [email, setEmail] = useState('');
  const [verified, setVerified] = useState(false);
  const [valid, setValid] = useState(false);
  const [resetting, setResetting] = useState();
  const [completed, setCompleted] = useState(false);

  const [newPassword, setNewPassword] = useState('');
  const [newPasswordAgain, setNewPasswordAgain] = useState('');
  const [newPasswordValid, setNewPasswordValid] = useState(false);
  const [newPasswordAgainValid, setNewPasswordAgainValid] = useState(false);
  const [newPasswordErrorText, setNewPasswordErrorText] = useState('');
  const [newPasswordAgainErrorText, setNewPasswordAgainErrorText] =
    useState('');

  useEffect(() => {
    const verifyToken = async () => {
      const urlToken = new URLSearchParams(search).get('token');
      const urlEmail = new URLSearchParams(search).get('email');

      if (!urlToken || !urlEmail) {
        history.push('/');
        return;
      }

      await UserService.VerifyToken(urlToken, urlEmail)
        .then((response) => {
          if (response.data === true) {
            setToken(urlToken);
            setEmail(urlEmail);
            setValid(true);
          } else {
            setValid(false);
          }
        })
        .catch(() => {
          setValid(false);
        })
        .finally(() => {
          setVerified(true);
        });
    };

    verifyToken();
  }, [search]);

  const handleCreateSubmit = async (event) => {
    event.preventDefault();
    setResetting(true);
    await UserService.ResetPassword(token, email, newPassword)
      .then(() => {
        setCompleted(true);
      })
      .catch(() => {
        setValid(false);
      })
      .finally(() => {
        setResetting(false);
      });
  };

  const validateForm = () => {
    return newPasswordValid && newPasswordAgainValid;
  };

  const validateNewPassword = (newPw) => {
    if (newPw.length < 8) {
      setNewPasswordErrorText(
        'Det nya lösenordet måste innehålla minst 8 tecken.'
      );
      setNewPasswordValid(false);
      return;
    }
    if (newPw.length > 250) {
      setNewPasswordErrorText('Det nya lösenordet är för långt.');
      setNewPasswordValid(false);
      return;
    }

    setNewPasswordValid(true);
    setNewPasswordErrorText('');
  };

  const validateNewPasswordAgain = (newPwAgain) => {
    if (newPwAgain !== newPassword) {
      setNewPasswordAgainErrorText('Lösenordet matchar inte');
      setNewPasswordAgainValid(false);
      return;
    }

    setNewPasswordAgainErrorText('');
    setNewPasswordAgainValid(true);
  };

  return (
    <div className="reset-password-wrapper">
      {verified && (
        <>
          <div className="reset-password-header">
            <XpektorLogo darkMode />
          </div>
          <div className="reset-password-content">
            <div className="reset-title">
              <p>Skapa ett nytt lösenord</p>
            </div>
            <div className="reset-password-form-container">
              {valid ? (
                !completed ? (
                  <XpektorForm onSubmit={handleCreateSubmit}>
                    <FormInput
                      label="Lösenord"
                      type="password"
                      value={newPassword}
                      onChange={(e) => {
                        setNewPassword(e.target.value);
                        validateNewPassword(e.target.value);
                      }}
                      infoText={newPasswordErrorText}
                    />
                    <FormInput
                      label="Bekräfta lösenordet"
                      type="password"
                      value={newPasswordAgain}
                      onChange={(e) => {
                        setNewPasswordAgain(e.target.value);
                        validateNewPasswordAgain(e.target.value);
                      }}
                      infoText={newPasswordAgainErrorText}
                    />
                    <FormButton
                      text={resetting ? 'Skapar...' : 'Skapa'}
                      disabled={!validateForm() || resetting}
                    />
                  </XpektorForm>
                ) : (
                  <>
                    <p className="completed-text">Lösenordet ändrat!</p>
                    <XpektorButton
                      content="Till inloggning"
                      onClick={() => history.push('/login')}
                    />
                  </>
                )
              ) : (
                <p className="invalid-text">Länken är inte giltig.</p>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
