/* eslint-disable react/prop-types */
import './ProcessCard.scss';

export default function ProcessCard({ image, title, text }) {
  return (
    <div className="process-card">
      <img src={image} alt="process-card" />
      <p className="card-title">{title}</p>
      <p className="card-text">{text}</p>
    </div>
  );
}
