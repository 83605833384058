import { useHistory } from 'react-router-dom';
import HomeNav from './HomeNav';
import Footer from './Components/Footer/Footer';
import RoundedButton from '../../Components/RoundedButton/RoundedButton';
import HelpOfferSection from './Components/HelpOfferSection';
import SpiderWebSection from './Components/SpiderWebSection';
import BannerSection from './Components/BannerSection';
import Background, { BackgroundElement } from './Components/Background';
import { OpenDemoLink } from '../../Components/Utilities/HelperFunctions';

// Media
import SwirlyLines2 from '../../Images/Home/swirly_lines_2.png';
// import SwarmWave from '../../Images/Home/swarm_wave.png';
import HelpOffer3 from '../../Images/Home/help_offer_3.png';
import AdviceProduct from '../../Images/Home/product_score.png';
import AdviceWeb from '../../Images/Home/xpektor_web_advice.svg';

export default function Advice() {
  const history = useHistory();

  return (
    <div className="xpektor-home">
      <div className="other-intro advice-intro">
        <HomeNav />
        <div className="section intro-section">
          <p className="section-title">Advice</p>
          <h1>Redovisning & Revision</h1>
          <div className="button-row">
            <RoundedButton
              text="Läs mer"
              fillCyan
              onClick={() => {
                history.push('/why');
              }}
            />
            <RoundedButton text="Boka samtal med oss" onClick={OpenDemoLink} />
          </div>
        </div>
      </div>
      <Background color1="#000b4f">
        <div className="intro-row-section advice-intro-row">
          <h3>Bli aktiverad med automatiska rådgivningstips</h3>
          <p className="section-text">
            Xpektor är din digitala medhjälpare som arbetar dygnet runt för att
            spara tid, skapa fler rådgivningstillfällen och stärka dina
            kundrelationer. Med vår egenutvecklade ekonomiska analys levererar
            Xpektor automatiska rådgivningstips som hjälper dig att prioritera
            rätt och ta din rådgivning till nästa nivå.
          </p>
        </div>
      </Background>
      <Background color1="#0316F3">
        <div className="section post-intro-section">
          <h1>Ligg alltid steget före</h1>
          <div className="post-intro-content">
            <div className="image-column">
              <img src={AdviceProduct} alt="product_image" />
            </div>
            <div className="text-column">
              <h3>Låt oss hjälpa dig hjälpa dina kunder</h3>
              <p className="section-text">
                Med Xpektor får du ett komplement till din klassiska
                månadsrapportering. Vi hjälper dig att rådge löpande under
                månaden till dina kunder, både i realtid men även prediktivt,
                vilket innebär att du alltid kan förekomma eventuella problem
                och risker som kan tänkas uppstå.
              </p>
              <RoundedButton text="Boka demo" fillCyan onClick={OpenDemoLink} />
            </div>
          </div>
        </div>
      </Background>
      <Background color1="#000B4F" color2="#010024">
        <HelpOfferSection
          image={HelpOffer3}
          title="Löpande rådgiving tack vare ständigt uppdaterad information"
          points={[
            'Med automatisk prioritering vet du vilken kund som behöver dig mest',
            'Smart aktivering ger automatiska rådgivningstips och analyser',
            'Få framåtblickande insikter för att skapa värde vid rätt tillfälle',
            'Säkerställ trygghet och kontroll genom att upptäcka risker i tid',
          ]}
        />
        <div className="section-with-element">
          <SpiderWebSection
            title="Hur fungerar det?"
            text="Xpektor är tjänsten som i realtid screenar och bevakar företag genom att korsmäta, validera och analysera fyra vertikaler samtidigt: Affärssystem (bokföring), Bank, Skatteverket och Bolagsverket. Informationen sammanställs och presenteras utifrån ett antal olika moduler inklusive vårt eget score för att skapa en bra överblick. Xpektor uppdateras var 4e minut för att säkerställa aktuell data."
            image={AdviceWeb}
          />
          <BackgroundElement img={SwirlyLines2} yPos={-400} />
        </div>
      </Background>
      <Background end>
        <BannerSection
          sectionTitle="TESTA XPEKTOR KOSTNADSFRITT"
          title="Att leverera förstaklassig rådgivning har aldrig varit enklare och säkrare"
          text="Börja spara tid och minska risk redan idag, du får alltid prova kostnadsfritt i 30 dagar. Med Xpektor får du full kontroll över dina kunder utan manuell handpåläggning."
          buttonText="Starta idag"
        />
        <Footer darkMode />
      </Background>
    </div>
  );
}
