/* eslint-disable react/prop-types */
import './SidebarButton.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function SidebarButton({ title, active, click, icon }) {
  return (
    <div className="sidebar-button-wrapper">
      <button
        type="button"
        onClick={click}
        className={active ? 'active-bar' : ''}
      >
        <FontAwesomeIcon className="button-icon" icon={icon} />
        <p>{title}</p>
      </button>
    </div>
  );
}
