import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import HomeNav from './HomeNav';
import Footer from './Components/Footer/Footer';
import BannerSection from './Components/BannerSection';
import Background, { BackgroundElement } from './Components/Background';
import EmailSection from './Components/EmailSection';
import RoundedButton from '../../Components/RoundedButton/RoundedButton';
import {
  XpektorForm,
  FormInput,
  FormTextArea,
  FormButton,
} from '../../Components/XpektorForms/XpektorForms';
import { OpenDemoLink } from '../../Components/Utilities/HelperFunctions';

// Media
import SwirlyLines2 from '../../Images/Home/swirly_lines_2.png';

export default function Contact() {
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const history = useHistory();

  const sendMessage = (e) => {
    e.preventDefault();

    window.open(`mailto:info@xpektor.se?subject=${subject}&body=${message}`);
  };

  return (
    <div className="xpektor-home">
      <div className="other-intro contact-intro">
        <HomeNav />
        <div className="section intro-section">
          <p className="section-title">Kontakta oss</p>
          <h1>
            Vi arbetar med visionära grundare och allokerare som satsar på
            framtiden
          </h1>
          <div className="button-row">
            <RoundedButton
              text="Läs mer"
              fillCyan
              onClick={() => {
                history.push('/why');
              }}
            />
            <RoundedButton
              text="Boka ett samtal med oss"
              onClick={OpenDemoLink}
            />
          </div>
        </div>
      </div>
      <Background color1="#000B4F" color2="#010024">
        <div className="section-with-element">
          <div className="section">
            <div className="message-section">
              <h3>
                Hör av dig till oss så hjälper vi dig med dina behov och vision.
              </h3>
              <XpektorForm onSubmit={sendMessage}>
                <FormInput
                  label="Rubrik"
                  type="text"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
                <FormTextArea
                  label="Meddelande"
                  rows={6}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <FormButton text="Skicka meddelande" />
              </XpektorForm>
            </div>
          </div>
          <BackgroundElement img={SwirlyLines2} yPos={-200} />
        </div>
        <div className="section">
          <EmailSection />
        </div>
      </Background>
      <Background end>
        <BannerSection
          sectionTitle="TESTA XPEKTOR KOSTNADSFRITT"
          title="Att ta beslut i realtid har aldrig varit enklare och säkrare"
          text="Börja spara tid och minska risk redan idag, du får alltid prova kostnadsfritt i 30 dagar. Med Xpektor får du full kontroll över dina kunder utan manuell handpåläggning."
          buttonText="Starta idag"
        />
        <Footer darkMode />
      </Background>
    </div>
  );
}
