/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import {
  faExclamationTriangle,
  faDownload,
} from '@fortawesome/free-solid-svg-icons';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import { ResponsiveLine } from '@nivo/line';
import Moment from 'moment';
import Module, { previewGraphData, previewGraphProperties } from './Module';
import LoadingBar from '../../../../Components/LoadingBar/LoadingBar';
import DataPanel from '../../../../Components/DataPanel/DataPanel';
import XpektorButton from '../../../../Components/XpektorButton/XpektorButton';
import { TranslateTriggers } from '../../../../Components/Utilities/TriggerTexts';

const clampToNegative = (value) => (Number(value) > 0 ? 0 : value);

const formatDateTime = (dateTime) => {
  return Moment.utc(dateTime).local().format('YYYY-MM-DD HH:mm');
};

export default function Equity({ equityStatus, onPdfClick, onHistoryClick }) {
  const [translatedTriggers, setTranslatedTriggers] = useState([]);

  useEffect(() => {
    if (equityStatus !== null && Object.keys(equityStatus).length > 0) {
      setTranslatedTriggers(TranslateTriggers(equityStatus.triggers));
    }
  }, [equityStatus]);

  return (
    <Module>
      {equityStatus == null ? (
        <p className="module-empty-text">Finns ingen data för Eget kapital.</p>
      ) : equityStatus.financialReport == null ? (
        <LoadingBar active status="Hämtar data..." relative />
      ) : (
        <>
          <Module.Nav>
            <div />
            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
              <XpektorButton
                icon={faDownload}
                content="Spara som PDF"
                onClick={() =>
                  onPdfClick(() =>
                    document.getElementById('equity-pdf-wrapper')
                  )
                }
              />
            </div>
          </Module.Nav>
          <div id="equity-pdf-wrapper">
            <Module.KeyFigureSection>
              <Module.KeyFigure
                title="Aktiekapital"
                value={equityStatus.financialReport.shareCapital}
                valueType="currency"
                info="Aktiekapitalet är det kapital som bolaget har i ”bundet
              eget kapital”."
              />
              <Module.KeyFigure
                title="Bokfört resultat"
                value={equityStatus.financialReport.result * -1}
                valueType="currency"
                info="Det resultat man fått fram genom poster man bokfört."
              />
              <Module.KeyFigure
                title="Prediktivt resultat"
                value={
                  equityStatus.financialReport.calculatedResultBeforeTax * -1
                }
                valueType="currency"
                subTitle="före skatt"
                info="Summan av bokförda poster samt de
              poster som ännu ej är bokförda som ligger utanför
              redovisningen exkluderat beräknad skatt."
              />
              <Module.KeyFigure
                title="Prediktivt resultat"
                value={
                  equityStatus.financialReport.calculatedResultAfterTax * -1
                }
                valueType="currency"
                subTitle="efter skatt"
                info="Summan av bokförda poster samt de
              poster som ännu ej är bokförda som ligger utanför
              redovisningen inkluderat beräknad skatt."
              />
              <Module.KeyFigure
                title="Kapital att förbruka"
                value={
                  (equityStatus.financialReport.equity +
                    equityStatus.financialReport.shareCapital / 2 +
                    equityStatus.financialReport.result) *
                  -1
                }
                valueType="currency"
                info="Totalt eget kapital är det kapital du enligt Xpektor har
              rätt att utnyttja under året. Det är det halva bunda
              egnakapitalet + balanserat resultat eller ”fritt eget
              kapital”. Observera att eventuell beslutad utdelning
              måste vara bokförd."
              />
            </Module.KeyFigureSection>
            <Module.DataSection>
              <Module.DataColumn>
                <DataPanel title="Historik">
                  <ResponsiveLine
                    data={previewGraphData}
                    {...previewGraphProperties}
                  />
                  <DataPanel.Button
                    text="Visa historik"
                    onClick={onHistoryClick}
                  />
                </DataPanel>
                <DataPanel title="Reserver">
                  <DataPanel.ValueEntry
                    label="Obeskattade reserver"
                    value={equityStatus.financialReport.untaxedReservesSum * -1}
                    valueType="currency"
                    info="Tillgängliga reserver vid förbrukat eget kapital. Ingår ej
                  i prediktivt resultat."
                  />
                </DataPanel>
                <DataPanel title="Att ta hänsyn till" icon={faBell} background>
                  {translatedTriggers.map((data) => {
                    return (
                      <DataPanel.TriggerEntry
                        icon={faExclamationTriangle}
                        name={data.texts.name}
                        info={data.texts.info}
                        key={`${data.id} equity`}
                        tooltipId={`${data.id} equity`}
                      />
                    );
                  })}
                </DataPanel>
              </Module.DataColumn>
              <Module.DataColumn>
                <DataPanel title="Fakturor">
                  <DataPanel.Subtitle
                    title="Kundfakturor"
                    info="Kundfakturor som är skapade men ej bokförda i systemet."
                  />
                  <DataPanel.ValueEntry
                    label="Ej bokförda"
                    value={equityStatus.financialReport.invoiceSum}
                    valueType="currency"
                  />
                  <DataPanel.Subtitle
                    title="Leverantörsfakturor"
                    info="Leverantörsfakturor som är skapade men ej bokförda i
                    systemet."
                  />
                  <DataPanel.ValueEntry
                    label="Ej bokförda"
                    value={equityStatus.financialReport.supplierInvoiceSum * -1}
                    valueType="currency"
                  />
                  <DataPanel.Subtitle
                    title="Periodiseringar ej utförda"
                    info="Periodiseringar som ligger som kommande men är ej bokförda."
                  />
                  <DataPanel.ValueEntry
                    label="Kundfakturor"
                    value={equityStatus.financialReport.invoiceAccrualsSum}
                    valueType="currency"
                  />
                  <DataPanel.ValueEntry
                    label="Leverantörsfakturor"
                    value={
                      equityStatus.financialReport.supplierInvoiceAccrualsSum *
                      -1
                    }
                    valueType="currency"
                  />
                </DataPanel>
              </Module.DataColumn>
              <Module.DataColumn>
                <DataPanel title="Beräknad skatt på prediktivt resultat">
                  <DataPanel.ValueEntry
                    label="Skatt på prediktivt resultat"
                    value={clampToNegative(
                      equityStatus.financialReport.taxOnCalculatedResult
                    )}
                    valueType="currency"
                  />
                  <DataPanel.ValueEntry
                    label="Skatt på ej avdragsgilla kostnader"
                    value={clampToNegative(
                      equityStatus.financialReport.nonDeductibleExpensesSum * -1
                    )}
                    valueType="currency"
                  />
                  <DataPanel.ValueEntry
                    label="Summa"
                    value={
                      clampToNegative(
                        equityStatus.financialReport.taxOnCalculatedResult
                      ) +
                      clampToNegative(
                        equityStatus.financialReport.nonDeductibleExpensesSum *
                          -1
                      )
                    }
                    valueType="currency"
                  />
                </DataPanel>
                <DataPanel title="Övrigt">
                  <DataPanel.ValueEntry
                    label="Sista bokfört datum"
                    value={
                      equityStatus.financialReport.latestVoucher
                        ? formatDateTime(
                            equityStatus.financialReport.latestVoucher
                          )
                        : 'N/A'
                    }
                  />
                  <DataPanel.ValueEntry
                    label="Avskrivningar"
                    value={equityStatus.financialReport.depreciationsSum * -1}
                    valueType="currency"
                  />
                  <DataPanel.ValueEntry
                    label="Observationskonton grupp 1"
                    value={equityStatus.financialReport.observationAccount * -1}
                    valueType="currency"
                  />
                  <DataPanel.ValueEntry
                    label="Observationskonton grupp 2"
                    value={
                      equityStatus.financialReport.observationAccount2 * -1
                    }
                    valueType="currency"
                  />
                </DataPanel>
              </Module.DataColumn>
            </Module.DataSection>
          </div>
        </>
      )}
    </Module>
  );
}
