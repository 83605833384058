/* eslint-disable react/prop-types */
import './ArrowButton.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function ArrowButton({ onClick, icon, iconPosition, content }) {
  return (
    <button className="onboarding-icon-button" type="button" onClick={onClick}>
      {iconPosition === 'left' && <FontAwesomeIcon icon={icon} />}
      {content}
      {iconPosition === 'right' && <FontAwesomeIcon icon={icon} />}
    </button>
  );
}
