import './ClientAuthLinkModal.scss';
import Moment from 'moment';
import { Button, Modal } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import AuthService from '../../../Services/AuthService';
import XpektorButton from '../../../Components/XpektorButton/XpektorButton';
import LoadingBar from '../../../Components/LoadingBar/LoadingBar';

/* eslint-disable react/prop-types */
export default function ClientAuthLinkModal({ client, handleClose }) {
  const [externalAuthState, setExternalAuthState] = useState(null);
  const [authUrl, setAuthUrl] = useState(null);
  const [fetching, setFetching] = useState(true);
  const [creatingLink, setCreatingLink] = useState(false);

  const [animatingCopied, setAnimatingCopied] = useState(false);
  const [animatingCopiedCount, setAnimatingCopiedCount] = useState(0);

  useEffect(() => {
    const getExternalAuthState = async () => {
      await AuthService.getClientExternalAuthState(client.id)
        .then((response) => {
          if (response.data) {
            setExternalAuthState(response.data);
            setAuthUrl(
              `${window.location.protocol}//${window.location.host}/authorization?state=${response.data.id}`
            );
          }
        })
        .catch(() => {})
        .finally(() => {
          setFetching(false);
        });
    };
    if (Object.keys(client).length !== 0) {
      getExternalAuthState();
    }
  }, [client]);

  useEffect(() => {
    const animateCopied = () => {
      const newAnimatingCopiedCount = animatingCopiedCount + 1;
      if (newAnimatingCopiedCount === 2) {
        setAnimatingCopiedCount(0);
        setAnimatingCopied(false);
      } else {
        setAnimatingCopiedCount(newAnimatingCopiedCount);
      }
    };
    const timer = setTimeout(() => animatingCopied && animateCopied(), 1000);
    return () => clearTimeout(timer);
  }, [animatingCopiedCount, animatingCopied]);

  const createLink = async () => {
    setCreatingLink(true);
    await AuthService.createExternalAuthState(client.id, null)
      .then((response) => {
        setExternalAuthState(response.data);
        setAuthUrl(
          `${window.location.protocol}//${window.location.host}/authorization?state=${response.data.id}`
        );
      })
      .catch(() => {})
      .finally(() => {
        setCreatingLink(false);
      });
  };

  return (
    <Modal
      show
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="border-0 client-auth-link-modal-wrapper"
    >
      <Modal.Header>
        <Modal.Title>{`Aktiveringslänk för ${client.name}`}</Modal.Title>
        <Button className="btn-close" bsPrefix="button" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body>
        <div className="auth-link-content">
          {fetching === true ? (
            <LoadingBar relative active content="Hämtar.. " />
          ) : externalAuthState === null ? (
            <div className="auth-link-missing">
              <p>
                Skapa en aktiveringslänk som kan användas för att koppla
                bokföringssystem, bank och/eller skatteverket.
              </p>
              <XpektorButton
                loading={creatingLink}
                content="Skapa länk"
                onClick={() => {
                  createLink();
                }}
              />
            </div>
          ) : (
            <div className="auth-state-display">
              <p className="auth-state-text">
                <b>Senast använd: </b>
                {externalAuthState.lastAccessed
                  ? Moment(externalAuthState.lastAccessed).format(
                      'YYYY-MM-DD HH:mm'
                    )
                  : 'N/A'}
              </p>
              <p className="auth-state-text">
                <b>Skapad: </b>
                {externalAuthState.dateCreated
                  ? Moment(externalAuthState.dateCreated).format(
                      'YYYY-MM-DD HH:mm'
                    )
                  : 'N/A'}
              </p>
              <div className="auth-link-actions">
                <XpektorButton
                  content={
                    animatingCopied ? (
                      <FontAwesomeIcon
                        className="copied-check-icon"
                        icon={faCheck}
                      />
                    ) : (
                      'Kopiera länk'
                    )
                  }
                  onClick={() => {
                    navigator.clipboard.writeText(authUrl);
                    setAnimatingCopied(true);
                  }}
                />
                <XpektorButton
                  content="Öppna i ny flik"
                  onClick={() =>
                    window.open(authUrl, '_blank', 'noopener,noreferrer')
                  }
                />
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}
