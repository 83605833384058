import './Home.scss';
import HomeNav from './HomeNav';
import Footer from './Components/Footer/Footer';
import RoundedButton from '../../Components/RoundedButton/RoundedButton';
import HelpOfferSection from './Components/HelpOfferSection';
import SpiderWebSection from './Components/SpiderWebSection';
import BannerSection from './Components/BannerSection';
import ProcessCard from './Components/ProcessCard';
import LogoSection from './Components/LogoSection';
import Background, { BackgroundElement } from './Components/Background';
import { OpenDemoLink } from '../../Components/Utilities/HelperFunctions';

// Media
import SwirlyLines1 from '../../Images/Home/swirly_lines_1.png';
import SwirlyLines2 from '../../Images/Home/swirly_lines_2.png';
import SwarmWave from '../../Images/Home/swarm_wave.png';
// import ParticleWaveClip from '../../Videos/blue_partcles_futuristic.mov';
import HelpOffer1 from '../../Images/Home/help_offer_1.png';
import ProcessImage1 from '../../Images/Home/process_card_1.png';
import ProcessImage2 from '../../Images/Home/process_card_2.png';
import ProcessImage3 from '../../Images/Home/process_card_3.png';
import LandingWeb from '../../Images/Home/xpektor_web_landing.svg';

export default function Landing() {
  return (
    <div className="xpektor-home">
      <div className="landing-intro">
        <HomeNav />
        <div className="section intro-section">
          <h1>Bedöm risker och möjligheter i realtid</h1>
          <p className="section-text">
            Bedöm dina kunders riskprofil, identifiera nya affärsmöjligheter och
            fatta rätt beslut - allt i realtid med vår mjukvara.
          </p>
          <RoundedButton text="Boka demo" fillCyan onClick={OpenDemoLink} />
        </div>
      </div>
      <Background color1="#000B4F">
        <div className="section-with-element">
          <div className="section landing-2">
            <p className="section-title">XPEKTOR</p>
            <h2>Effektiva företagsbeslut börjar här</h2>
            <p className="section-text">
              I realtid skapar Xpektor en samlad bild över bokföring,
              banktransaktioner, skattekonto och regulatorisk
              företagsinformation. Vi hämtar och korsmäter flera datakällor för
              att leverera en automatiserad analys med allt du behöver för att
              fatta beslut. Genom att bevaka både nuvarande och framtida
              händelser i företaget är Xpektor din digitala medhjälpare som
              jobbar dygnet runt.
            </p>
          </div>
          <BackgroundElement img={SwirlyLines1} yPos={-200} />
        </div>
        {/* <div className="section video-section">
          <video autoPlay muted loop>
            <source src={ParticleWaveClip} />
          </video>
        </div> */}
        <div className="section-with-element">
          <HelpOfferSection
            image={HelpOffer1}
            title="Xpektor hjälper dig att ta snabbare och bättre beslut"
            points={[
              'Spara värdefull tid med kompletta beslutsunderlag',
              'Undvik bedrägerier och penningtvätt genom vår automatiserade riskanalys',
              'Automatiska tips och analyser i realtid',
              'Hitta nya affärsmöjligheter och öka din lönsamhet',
            ]}
          />
          <BackgroundElement img={SwirlyLines2} yPos={400} />
        </div>
      </Background>
      <Background color1="#000B4F" color2="#010024">
        <div className="section-with-element">
          <SpiderWebSection
            title="Hur fungerar det?"
            text="Xpektor är tjänsten som i realtid screenar och bevakar företag genom att korsmäta, validera och analysera fyra vertikaler samtidigt: Affärssystem (bokföring), Bank, Skatteverket och Bolagsverket. Informationen sammanställs och presenteras utifrån ett antal olika moduler inklusive vårt eget score för att skapa en bra överblick. Xpektor uppdateras var 4e minut för att säkerställa aktuell data."
            image={LandingWeb}
          />
          <BackgroundElement img={SwarmWave} yPos={700} />
        </div>
        <div className="section home-4">
          <p className="section-title">PROCESS</p>
          <h2>Trött på komplicerade processer?</h2>
          <div className="row-content">
            <ProcessCard
              image={ProcessImage1}
              title="Anslut"
              text="Logga in och använd Xpektor direkt via vår webbplats, eller integrera lösningen smidigt med dina befintliga system. Med vår plug-in-lösning får du all funktionalitet du behöver, utan krångel, för en smidig och snabb installation."
            />
            <ProcessCard
              image={ProcessImage2}
              title="Uteslut"
              text="Få omedelbara uppdateringar när något viktigt händer. Med realtidsnotiser är du alltid ett steg före och kan agera snabbt och effektivt för att optimera dina processer och beslutstider."
            />
            <ProcessCard
              image={ProcessImage3}
              title="Beslut"
              text="Se helheten och få en tydlig översikt med vårt score och använd Xpektors analys för att fatta välgrundade beslut."
            />
          </div>
        </div>
        <div className="section-with-element">
          <LogoSection />
          <BackgroundElement img={SwirlyLines2} yPos={-300} />
        </div>
      </Background>
      <Background end>
        <BannerSection
          sectionTitle="TESTA XPEKTOR KOSTNADSFRITT"
          title="Att ta beslut i realtid har aldrig varit enklare och säkrare"
          text="Börja spara tid och minska risk redan idag, du får alltid prova kostnadsfritt i 30 dagar. Med Xpektor får du full kontroll över dina kunder utan manuell handpåläggning."
          buttonText="Starta idag"
        />
        <Footer darkMode />
      </Background>
    </div>
  );
}
