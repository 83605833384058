/* eslint-disable react/prop-types */
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck,
  faArrowRight,
  faArrowUpRightFromSquare,
} from '@fortawesome/free-solid-svg-icons';
import ClientService from '../../../Services/ClientService';
import FortnoxLogo from '../../../Images/Logos/fortnox-svg.svg';
import VismaLogo from '../../../Images/Logos/Visma_spcs_logo.svg';
import BjornLundenLogo from '../../../Images/Logos/Bjorn-Lunden-logo.png';
// import HogiaLogo from '../../../Images/Logos/hogia-logo.png';
import CardButton from '../../../Components/CardButton/CardButton';
import {
  XpektorForm,
  FormInput,
  FormButton,
} from '../../../Components/XpektorForms/XpektorForms';

export default function AccountingAuthStep({
  completed,
  redirectToFortnox,
  redirectToVisma,
  onValidation,
  error,
  errorText,
  extStateId,
}) {
  const [keyInput, setKeyInput] = useState('');
  const [inputError, setInputError] = useState('');
  const [validatingBL, setValidatingBL] = useState();
  // const [validatingHogia, setValidatingHogia] = useState();

  const validateBjornLunden = async (event) => {
    event.preventDefault();

    if (keyInput.length === 0) {
      setInputError('Fältet kan inte vara tomt');
      return;
    }

    setValidatingBL(true);
    await ClientService.authenticateAccounting(keyInput, 3, extStateId)
      .then(() => {
        onValidation();
      })
      .catch(() => {
        setInputError('Hittade ingen koppling');
      })
      .finally(() => {
        setValidatingBL(false);
      });
  };

  /* const validateHogia = async (event) => {
    event.preventDefault();

    if (keyInput.length === 0) {
      setInputError('Fältet kan inte vara tomt');
      return;
    }

    setValidatingHogia(true);
    await ClientService.authenticateAccounting(keyInput, 3, extStateId)
      .then(() => {
        onValidation();
      })
      .catch(() => {
        setInputError('Hittade ingen koppling');
      })
      .finally(() => {
        setValidatingHogia(false);
      });
  }; */

  return (
    <div className="auth-step">
      {completed ? (
        <div className="auth-step-completed-content">
          <FontAwesomeIcon
            className="auth-complete-icon"
            icon={faCircleCheck}
          />
          <p className="auth-complete-header">Bokföringssystemet är kopplat!</p>
        </div>
      ) : (
        <>
          <p className="auth-step-header">Koppla på bokföring</p>
          <p className="auth-step-description">
            Genom att koppla ditt bokföringssystem till Xpektor förenklas
            handläggningen. Xpektor hämtar automatiskt den information som
            behövs för att fatta beslut och du slipper själv skicka rapporter
            manuellt. Handläggaren får en klar bild över företagets ekonomi och
            processen kan snabbas på.
          </p>
          <button
            type="button"
            className="auth-step-help-link"
            onClick={() =>
              window.open('/tutorials', '_blank', 'noopener,noreferrer')
            }
          >
            <p>Läs mer om integrationslicenser</p>
            <FontAwesomeIcon
              className="auth-step-help-link-icon"
              icon={faArrowUpRightFromSquare}
            />
          </button>
          {error ? (
            <div className="auth-step-error">
              <p>
                {errorText ??
                  'Något gick fel och det gick inte att koppla bokföringssystemet. '}
                <a href="/tutorials" target="_blank" rel="noopener noreferrer">
                  Tryck här för hjälp.
                </a>
              </p>
            </div>
          ) : (
            ''
          )}
          <div className="integration-options-column">
            <CardButton
              textHeader="Fortnox"
              textContent="Kräver en integrationslicens i Fortnox"
              icon={faArrowRight}
              image={FortnoxLogo}
              onClick={redirectToFortnox}
            />
            <CardButton
              textHeader="Visma"
              textContent="Kräver en integrationslicens i Visma eAccounting"
              icon={faArrowRight}
              image={VismaLogo}
              onClick={redirectToVisma}
            />
            <CardButton
              textHeader="Björn Lundén"
              textContent="Kräver att Xpektor är tillagd och aktiverad som integration i Björn Lundén"
              icon={faArrowRight}
              image={BjornLundenLogo}
              onClick={() => {
                setKeyInput('');
                setInputError('');
              }}
              expandedContent={
                <>
                  <XpektorForm onSubmit={validateBjornLunden}>
                    <FormInput
                      label="Public key"
                      type="text"
                      value={keyInput}
                      onChange={(e) => setKeyInput(e.target.value)}
                      infoText={inputError}
                    />
                    <FormButton
                      text={validatingBL ? 'Validerar...' : 'Validera'}
                      disabled={validatingBL}
                    />
                  </XpektorForm>
                  <a href="/tutorials" className="additional-link">
                    Så här gör du
                  </a>
                </>
              }
            />
            {/* <CardButton
              textHeader="Hogia"
              textContent="Kräver en integrationslicens i Hogia"
              icon={faArrowRight}
              image={HogiaLogo}
              onClick={() => {
                setKeyInput('');
                setInputError('');
              }}
              expandedContent={
                <XpektorForm onSubmit={validateHogia}>
                  <XpektorForm.Input
                    label="Public key"
                    placeholder="Ex: 0a303ec5-80ff-4041-a721-d04ba67d3eec"
                    type="text"
                    value={keyInput}
                    onChange={(e) => setKeyInput(e.target.value)}
                    infoText={inputError}
                  />
                  <XpektorForm.Button
                    text={validatingHogia ? 'Validerar...' : 'Validera'}
                    disabled={validatingHogia}
                  />
                </XpektorForm>
              }
            /> */}
          </div>
        </>
      )}
    </div>
  );
}
