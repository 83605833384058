import { useHistory } from 'react-router-dom';
import HomeNav from './HomeNav';
import Footer from './Components/Footer/Footer';
import BannerSection from './Components/BannerSection';
import Background, { BackgroundElement } from './Components/Background';
import EmailSection from './Components/EmailSection';
import RoundedButton from '../../Components/RoundedButton/RoundedButton';
import { OpenDemoLink } from '../../Components/Utilities/HelperFunctions';

// Media
import SwirlyLines1 from '../../Images/Home/swirly_lines_1.png';
import FounderImage from '../../Images/Home/D&H.png';
import LocationImage from '../../Images/Home/office_location.png';

export default function About() {
  const history = useHistory();

  return (
    <div className="xpektor-home">
      <div className="other-intro about-intro">
        <HomeNav />
        <div className="section intro-section">
          <p className="section-title">Om oss</p>
          <h1>Vi revolutionerar beslutsfattandet för företag</h1>
          <div className="button-row">
            <RoundedButton
              text="Läs mer"
              fillCyan
              onClick={() => {
                history.push('/why');
              }}
            />
            <RoundedButton
              text="Boka ett samtal med oss"
              onClick={OpenDemoLink}
            />
          </div>
        </div>
      </div>
      <Background color1="#000B4F" color2="#010024">
        <div className="section-with-element">
          <div className="section founder-section">
            <p className="section-title">XPEKTOR</p>
            <h2>
              Xpektor grundades med visionen att revolutionera beslutsfattande
              för företag aktiva inom finans och redovisning genom att
              tillhandahålla viktig prediktiv information i realtid.
            </h2>
            <div className="founder-content">
              <img src={FounderImage} alt="founder_image" />
              <p className="section-text">
                Xpektors grundare. Hanna Gårdmark, Daniel Eremar och Petter
                Eriksson har tillsammans en gedigen erfarenhet av affärssystem,
                finansiella marknader och mjukvaruutveckling. De identifierade
                tidigt behovet av att kombinera data från både bokföring och
                banktransaktioner för att skapa en heltäckande analys av
                företag. Genom detta kunde de inte bara möjliggöra mer korrekta
                finansiella beslut, utan även bidra till att upptäcka risker som
                bedrägerier och penningtvätt. Resultatet blev Xpektor – en SaaS
                produkt som idag hjälper kredit- och rådgivningsbranschen att
                säkert validera sina kunder i realtid och stärka deras
                affärsrelationer.
              </p>
            </div>
          </div>
          <BackgroundElement img={SwirlyLines1} yPos={-200} />
        </div>
        <div className="section location-section">
          <div className="text-column">
            <h3>Hitta till oss</h3>
            <p className="section-text">
              Vi befinner oss i Göteborg på Hvitfeldtsplatsen 7.
            </p>
          </div>
          <img src={LocationImage} alt="location_image" />
        </div>
        <div className="section">
          <EmailSection />
        </div>
      </Background>
      <Background end>
        <BannerSection
          sectionTitle="TESTA XPEKTOR KOSTNADSFRITT"
          title="Att ta beslut i realtid har aldrig varit enklare och säkrare"
          text="Börja spara tid och minska risk redan idag, du får alltid prova kostnadsfritt i 30 dagar. Med Xpektor får du full kontroll över dina kunder utan manuell handpåläggning."
          buttonText="Starta idag"
        />
        <Footer darkMode />
      </Background>
    </div>
  );
}
